import { http_client } from "@/plugins/http_client";

const getNotificaciones = async (params = {}) => {
  return await http_client(`/api/v1/notificaciones`, params, "get");
};

const readAllNotifications = async (params = {}) => {
  return await http_client(`/api/v1/notificaciones/readed/all`, params, "put");
};

const markAsRead = async (id_notificacion, params = {}) => {
  return await http_client(
    `/api/v1/notificaciones/${id_notificacion}/readed`,
    params,
    "put"
  );
};

const deleteNotification = async (id_notificacion, params = {}) => {
  return await http_client(
    `/api/v1/notificaciones/${id_notificacion}`,
    params,
    "delete"
  );
};

export default {
  getNotificaciones,
  readAllNotifications,
  markAsRead,
  deleteNotification,
};
