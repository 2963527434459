export default () => ({
  dataInsumo: [
    {
      id: 1,
      codigo: "14111500",
      nombre: "Papel de imprenta y papel de escribir",
      techo_asignado: 1000,
      fondos_necesarios: 50,
      saldo: 953,
      listado_insumos: [
        {
          correlativo: 1,
          mercancia: "Resmas de papel",
          unidad_medida: "Resmas",
          mes: "Enero",
          cantidad: 20,
          precio_unitario: 2.5,
          total_global: 50,
          //   cantidad_aprobar: 20,
          //   total_local: 50,
          enlace_compra: "https://www.google.com",
          estado: false,
        },
        {
          correlativo: 2,
          mercancia: "Papel de imprenta",
          unidad_medida: "Resmas",
          mes: "Febrero",
          cantidad: 20,
          precio_unitario: 2.5,
          total_global: 50,
          enlace_compra: "https://www.google.com",
          estado: false,
        },
        {
          correlativo: 3,
          mercancia: "Papel de imprenta",
          unidad_medida: "Resmas",
          mes: "Marzo",
          cantidad: 20,
          precio_unitario: 2.5,
          total_global: 50,
          enlace_compra: "https://www.google.com",
          estado: false,
        },
        {
          correlativo: 4,
          mercancia: "Papel de imprenta",
          unidad_medida: "Resmas",
          mes: "Abril",
          cantidad: 20,
          precio_unitario: 2.5,
          total_global: 50,
          enlace_compra: "https://www.google.com",
          estado: false,
        },
      ],
      estado: false,
    },
    {
      id: 1,
      codigo: "14111501",
      nombre: "Lapiceros",
      techo_asignado: 1000,
      fondos_necesarios: 50,
      saldo: 953,
      listado_insumos: [],
      estado: true,
    },
  ],
  entrega: {
    porcentaje: 25,
    fechaInicio: "06/09/2022",
    fechaFinalizacion: "06/09/2022",
    monto: 4000,
  },
  anioFiscal: null,
  insumosASolicitar: [
    {
      id: 1,
      mercancia: "Lapiceros azules",
      unidad_medida: "Unidad",
      total: "70",
      estado: {
        nombre: "Pendiente",
        color: "#C1ECD9",
      },
      enlace_compra: "https://google.com",
      acciones: "",
    },
  ],
  fondosAsignados: [],
  solicitudesCompraList: [],
  filters: {
    page: 1,
    per_page: 10,
    total_rows: null,
    id_estado: null,
    id_tipo_solicitud: null,
    fecha_desde: "",
    fecha_hasta: "",
    id_unidad: null,
  },
  pasoSolicitud: null,
  correlativoSolicitud: null,
  pasosSolicitudes: {
    elaborada: 2,
    aprobadaUnidad: 3,
    verificadaUACI: 4,
    aprobadoUFI: 5,
    aprobadoUACI: 6,
    cancelada: 7,
    observacion: 8,
    borrador: 1,
    canceladoUnidad: 9,
    canceladoUFI: 10,
    canceladoUACI: 11,
    observadoUFI: 13,
    observadoUACI: 12,
  },
  lista_administradores_de_contrato: [],
  bitacora: [],
});
