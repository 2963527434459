// Mutaciones para revisión de observaciones
export const setSolicitudConObservaciones = (state, payload) => {
  state.solicitud_con_observaciones = payload.value;
  state.token = payload.token;
  state.id_proveedor = payload.id_proveedor;
};

export const setObservaciones = (state, payload) => {
  state.observaciones = payload;
};

export const setFormularioUno = (state, payload) => {
  state.formulario_uno = payload;
  state.tipo_identificaciones_disable = false;
};

export const setFormularioDos = (state, payload) => {
  state.formulario_dos = payload;
};

export const setFormularioTres = (state, payload) => {
  state.formulario_tres = payload;
};

export const setDeclaracionJuradaObservacion = (state, payload) => {
  state.formulario_tres.archivo_declaracion = payload;
};

// Mutaciones generales
export const setPasoActual = (state, paso_actual) => {
  state.paso_actual = paso_actual;
};

export const setTipoContribuyentes = (state, payload) => {
  state.tipo_contribuyentes = payload;
};

export const setEstadoInicial = (state) => {
  state.paso_actual = 1;
  state.solicitud_con_observaciones = false;
  state.token = "";
  state.observaciones = [];
  state.formulario_uno = {
    id_tipo_contribuyente: null,
    tipo_contribuyente: null,
    id_tipo_identificacion: null,
    tipo_identificacion: {},
    numero_documento: null,
    nombre_proveedor: null,
  };
  state.tipo_contribuyentes = [];
  state.tipo_identificaciones_disable = true;
  state.tipo_identificaciones = [];
  state.mascara_documento = "";
  state.lng_doc = 0;
  state.dui_verificado = false;
  state.verificando_dui = false;
  state.es_salvadoreno = false;
  state.formulario_dos = {
    nombre_comercial: null,
    sitio_web: null,
    genero: null,
    id_clasificacion_empresarial: null,
    id_pais: null,
    id_departamento: null,
    id_municipio: null,
    direccion: null,
    area: null,
    telefono: null,
  };
  state.mascara_telefono = "";
  state.lng_tel = 0;
  state.generos = [];
  state.clasificacion_empresarial = [];
  state.paises = [];
  state.pais_disable = true;
  state.departamentos = [];
  state.departamentos_disable = true;
  state.municipios = [];
  state.municipios_disable = true;
  state.loading_paso_dos = false;
  state.id_proveedor = null;
  state.formulario_tres = {
    correo_electronico: null,
    codigo_verificacion: "",
    terminos: null,
    declaracion: null,
    archivo_declaracion: null,
  };
  state.enviar_codigo_disable = false;
  state.loading_verificar_correo = false;
  state.correo_valido = false;
  state.loading_guardar_registro = false;
  state.tiempoEsperaCorreo = 0;
  state.verified = false;
  state.verified2 = false;
};

// Mutaciones del paso 1
export const setTipoContribuyenteSeleccionado = (state, payload) => {
  state.formulario_uno.tipo_contribuyente = payload;
};

export const setTipoIdentificaciones = (state, payload) => {
  state.tipo_identificaciones = payload;
  state.tipo_identificaciones_disable = false;
};

export const setTipoIdentificacionSeleccionada = (state, payload) => {
  state.formulario_uno.id_tipo_identificacion = payload.id_tipo_identificacion;
  state.formulario_uno.numero_documento = "";
  state.dui_verificado = false;
  state.mascara_documento = payload.mascara_documento;
  state.lng_doc = payload.lng_doc;
  state.formulario_uno.tipo_identificacion = payload.tipo_identificacion;
  
    // Reiniciamos el id del pais, departamento y municipio
    state.formulario_dos.id_pais = null;
    state.formulario_dos.id_departamento = null;
    state.formulario_dos.id_municipio = null;1
    // Limpiar código de area
    state.formulario_dos.area = null;
};

export const setNullTipoIdentificacion = (state) => {
  state.formulario_uno.id_tipo_identificacion = null;
  state.formulario_uno.tipo_identificacion = {};
}

export const setVerificandoDui = (state, payload) => {
  state.verificando_dui = payload;
};

export const setDuiVerificado = (state, payload) => {
  state.dui_verificado = payload;
};

export const setNombreProveedor = (state, payload) => {
  state.formulario_uno.nombre_proveedor = payload;
};

export const serNumeroDocumento = (state, payload) => {
  state.formulario_uno.numero_documento = payload;
};

export const setMascaraDocumento = (state, payload) => {
  state.mascara_documento = payload;
};

export const setLngDoc = (state, payload) => {
  state.lng_doc = payload;
};

export const setEsSalvadoreno = (state, payload) => {
  state.es_salvadoreno = payload;
};

export const setDepartamentoId = (state, payload) => {
  state.formulario_dos.id_departamento = payload;
};

export const setMunicipioId = (state, payload) => {
  state.formulario_dos.id_municipio = payload;
};

// Mutaciones del paso 2
export const setGeneros = (state, payload) => {
  state.generos = payload;
};

export const setClasificacionEmpresarial = (state, payload) => {
  state.clasificacion_empresarial = payload;
};

export const setPaises = (state, payload) => {
  state.paises = payload;
};

export const setIdPais = (state, payload) => {
  state.formulario_dos.id_pais = payload;
};

export const setPaisDisable = (state, payload) => {
  state.pais_disable = payload;
};

export const setDepartamentos = (state, payload) => {
  state.departamentos = payload;
  state.departamentos_disable = false;
};

export const setMunicipios = (state, payload) => {
  state.municipios = payload;
  state.municipios_disable = false;
};

export const setCodigoArea = (state, payload) => {
  state.formulario_dos.area = payload;
};

export const setMascaraTelefono = (state, payload) => {
  state.mascara_telefono = payload;
};

export const setLngTel = (state, payload) => {
  state.lng_tel = payload;
};

export const setLoadingPasoDos = (state, payload) => {
  state.loading_paso_dos = payload;
};

export const setIdProveedor = (state, payload) => {
  state.id_proveedor = payload;
};

export const setRegistroProveedor = (state, payload) => {
  state.datos = payload;
};
export const setsegundopaso = (state, payload) => {
  state.segundosDatos = payload;
};
export const setidproveedorOld = (state, payload) => {
  state.Proveedorid = payload;
};
export const setUltimoPaso = (state, payload) => {
  state.TercerDatos = payload;
};
export const setVerificacion = (state, payload) => {
  state.verified = payload;
};
export const setVerificacionDos = (state, payload) => {
  state.verified2 = payload;
};
export const setTiempoEsperaCorreo = (state, payload) => {
  state.tiempoEsperaCorreo = payload;
};
export const setStep = (state, payload) => {
  state.step = payload;
};

// Mutaciones del paso 3
export const setVerificandoCorreo = (state, payload) => {
  state.loading_verificar_correo = payload;
};

export const setCorreoValido = (state, payload) => {
  state.correo_valido = payload;
}

export const setEnviarCodigoDisable = (state, payload) => {
  state.enviar_codigo_disable = payload;
}

export const setLoadingGuardarRegistro = (state, payload) => {
  state.loading_guardar_registro = payload;
}