const seguimientoProcesosRoutes = [
    {
        path: "/seguimiento-procesos",
        name: "seguimiento-procesos",
        component: () =>
            import(/* webpackChunkName: "seguimientoProcesos" */ "./seguimientoProcesos.vue")
    },
    {
        path: "/seguimiento-procesos/detalle/:id_seguimiento_proceso",
        name: "seguimiento-procesos-detalle",
        component: () =>
            import(/* webpackChunkName: "detalleSeguimientoProceso" */ "./detalleSeguimientoProceso.vue")
    },
]

export default seguimientoProcesosRoutes;
