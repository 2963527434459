<template>
  <v-icon @click="goBack" color="primary" large>
    mdi mdi-arrow-left-circle
  </v-icon>
</template>
  
<script>
export default {
  methods: {
    goBack() {
      this.$router.back();
    },
  },
};
</script>