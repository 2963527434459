import { http_client, http_client_with_status } from "@/plugins/http_client";

// petición GET
const getListFondos = (filters) => http_client('/api/v1/compras-centros-escolares', filters);

const obtenerFondosEncargados = (filters) => http_client('/api/v1/compras-centros-escolares/fondos-encargados', filters)

const getListRegistrosFondo = (id_fondo, filters) => 
  http_client_with_status(`/api/v1/compras-centros-escolares/revision-registros-fondo-compra-centro-escolar/${id_fondo}`, filters, 'get');
const getListRegsFondos = (id_fondo, filters) => http_client(`/api/v1/compras-centros-escolares/${id_fondo}/registros`, filters);
const getFondoById = (id_fondo, filter) => http_client(`/api/v1/compras-centros-escolares/${id_fondo}`, filter)
const getEmpleadoByEmail = (filter) => http_client(`/api/v1/compras-centros-escolares/administradores`, filter)

// petición POST
const postAddFondo = (body = {}) => http_client('/api/v1/compras-centros-escolares', body, 'post');
const postAddAdminFondo = (id_fondo, id_admin, body = {}) => http_client(`/api/v1/compras-centros-escolares/${id_fondo}/administradores/${id_admin}`, body, 'post');
const postAddRegFondo = (id_fondo, body = {}) => http_client(`/api/v1/compras-centros-escolares/${id_fondo}/registros`, body, 'post');

// petición PUT
const putEditFondo = (id_fondo, body = {}) => http_client(`/api/v1/compras-centros-escolares/${id_fondo}`, body, 'put');

// petición DELETE
const deleteAdminFondo = async (id_admin, id_fondo) => http_client(`/api/v1/compras-centros-escolares/administradores/${id_admin}`, {}, 'delete');

const marcarRegistroComoRevisado = (id_gasto) => http_client_with_status(`/api/v1/compras-centros-escolares/marcar-como-revisado/${id_gasto}`, null, 'patch');

const marcarRegistroComoObservado = (id_fondo, data) => http_client_with_status(`/api/v1/compras-centros-escolares/marcar-como-observado/${id_fondo}`, data, 'patch');

const observadaFondo = (id_gasto) => http_client_with_status(`/api/v1/compras-centros-escolares/observada/${id_gasto}`, null, 'patch');

const getReporteGeneralFondos = () =>
    http_client(
    "/api/v1/compras-centros-escolares/reporte/general",
    {},
    "get",
    { responseType: "blob" },
    true,
    "blob",
    false
  );

  const getReporteFondo = async (idFondo) =>
    http_client(
        `/api/v1/compras-centros-escolares/${idFondo}/reporte`,
        {},
        "get",
        { responseType: "blob" },
        true,
        "blob",
        false
    );

  const deleteGastoFondo = (id_gasto) => http_client(`/api/v1/compras-centros-escolares/registros/${id_gasto}`, {}, 'delete');

  const editarGastoFondo = (idRegistro, data) => http_client(`/api/v1/compras-centros-escolares/editar/registro/${idRegistro}`, data, 'put');

  const uploadExcel = (id_fondo, body = {}) => http_client(`/api/v1/compras-centros-escolares/reporte/${id_fondo}`, body, 'post');

  const postAsueto = (body = {}) => http_client('/api/v1/asuetos/fecha-fin', body, 'post');

const cargarInstituciones = (filtros) => http_client_with_status('/api/v1/compras-centros-escolares/cargar/instituciones', filtros, 'get');

const listarFondos = (filtros) => http_client_with_status('/api/v1/compras-centros-escolares/list/fondos', filtros, 'get');

const aprobarcionMasivaRegistros = (id_fondo, body = {}) => http_client(`/api/v1/compras-centros-escolares/registros-revisados/${id_fondo}`, body, 'post');

export default {
  obtenerFondosEncargados,
  getListFondos,
  getListRegsFondos,
  getFondoById,
  getEmpleadoByEmail,
  postAddFondo,
  postAddAdminFondo,
  postAddRegFondo,
  putEditFondo,
  deleteAdminFondo,
  getReporteGeneralFondos,
  getReporteFondo,
  deleteGastoFondo,
  uploadExcel,
  postAsueto,
  getListRegistrosFondo,
  marcarRegistroComoRevisado,
  observadaFondo,
  cargarInstituciones,
  listarFondos,
  marcarRegistroComoObservado,
  editarGastoFondo,
  aprobarcionMasivaRegistros,
}