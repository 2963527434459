import router from '@/router'
export default {
  namespaced: true,
  state: {
    listadoLotes: [],
    ofertaInfo: {},
  },
  mutations: {
    setOfertaTecnica(state, { proveedor, vm }) {
      state.ofertaInfo = proveedor.ofertas[0];
      state.listadoLotes = proveedor.ofertas[0]?.lotesOferta?.map((col) => ({
        ...col,
        obs: col.lote?.sub_procesos?.map((col2) => {
          return {
            nombre: col2.nombre_agrupacion,
            especificacion: col2.especificacion,
            unidad: col2.unidad,
            id: col2.id,
            unidad: col2.Presentacion?.nombre,
          };
        }),
        estado: router.currentRoute.params.id_oferta ? true : false,
      }));
    },

    setOfertaFinanciera(state, { proveedor, vm }) {
      state.listadoLotes = proveedor?.lotesOferta?.map((col) => ({
        ...col,
        obs: col.LotesProceso?.sub_procesos?.map((col2) => {
          const cantidad_adjudicada = router.currentRoute.params.id_oferta
            ? (col2?.ggsp?.length > 0 ? col2?.ggsp[0]?.cantidad : 0)
            : null;

          const cantidad_sin_adjudicar =
            Number(col2.total_sub_proceso) - Number(col2.total_adjudicado);

          return {
            nombre: col2.nombre_agrupacion,
            unidad: col2.Presentacion?.nombre,
            especificacion: col2.especificacion,
            cantidad_sin_adjudicar,
            monto_sub_proceso: Number(col2.monto_sub_proceso_obs),
            monto: router.currentRoute.params.id_oferta ? (col2?.ggsp?.length > 0 ? col2?.ggsp[0]?.monto : 0) : 0,
            cantidad_adjudicada,
            total_sin_adjudicar_inicial: Number(cantidad_sin_adjudicar),
            total_adjudicado_inicial: Number(cantidad_adjudicada),
            total_adjudicado:
              Number(col2.total_adjudicado) - Number(cantidad_adjudicada),
            id: col2.id,
            id_ganador_obs: router.currentRoute.params.id_oferta
              ? (col2?.ggsp?.length > 0 ? col2?.ggsp[0]?.id_ganador_obs : null)
              : null,
          };
        }),
        estado: router.currentRoute.params.id_oferta ? true : false,
      }));
    },
    clearListadoLotes(state){
        state.listadoLotes = [];
        state.ofertaInfo = {};
    }
  },
};
