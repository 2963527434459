const cmReporteriaRoutes = [
    {
        path: "/convenio-marco-reporteria",
        name: "convenio-marco-reporteria",
        component: () =>
            import(
                /* webpackChunkName: "creacionConvenio" */ "./reporteComprasView.vue"
                ),
    },
];

export default cmReporteriaRoutes;
