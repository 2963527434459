import Vue from "vue";
import store from "../store";
import moment from "moment";

Vue.prototype.showLoader = async () => {
  await store.commit("utils/setLoader", true);
};
Vue.prototype.hideLoader = async () => {
  await store.commit("utils/setLoader", false);
};

Vue.prototype.DocsProveedorValidados = async (ruta) => {
  return await store.dispatch('verificarValidacionDocs', ruta);
};

Vue.prototype.validarSancionesActivasProveedor = async (ruta) => {
  return await store.dispatch('validarSancionActivaProveedor', ruta);
};

Vue.prototype.haveRole = (role, skipAdminSearch = false) => {
  if (
    !skipAdminSearch &&
    store.state.userInfo.roles.some((item) => item === 'ROLE_SUPER_ADMIN') &&
    !store.state.userInfo.roles.some((item) => item === 'ROLE_PROVEEDOR')
  ) return true;
  return store.state.userInfo.roles.some((item) => item === role);
};

Vue.prototype.hasAllRoles = (target, skipAdminSearch = false) => {
  const roles = store.state.userInfo.roles;
  if (
    !skipAdminSearch &&
    roles.some((item) => item === 'ROLE_SUPER_ADMIN') && !roles.some((item) => item === 'ROLE_PROVEEDOR')
  ) return true;

  return target.every((role) => roles.includes(role));
};

Vue.prototype.haveRoles = (roles, skipAdminSearch = false) => {
  if (
    !skipAdminSearch &&
    store.state.userInfo.roles.some((item) => ['ROLE_SUPER_ADMIN'].includes(item)) &&
    !store.state.userInfo.roles.some((item) => ['ROLE_PROVEEDOR'].includes(item))
  ) return true;
  return store.state.userInfo.roles.some((item) => roles.includes(item));
};

/** @deprecated usar pushAppMessage */
Vue.prototype.temporalAlert = async (alert) => {
  const message = {
    type: alert.type,
    message: alert.message,
  }
  await store.commit('utils/pushAppMessage', message);
};

Vue.prototype.isEmail = (value) => {
  if (value === null) return false;
  return /^([a-zA-Z0-9./^S+$/<*>!#$%&'+/=?^_`{|}~-]+([s]{0}))+?@[a-zA-Z_-]+([.]{0,1})[a-zA-Z]+[s]{0}[.]?[a-zA-Z](([.]{1})([a-zA-Z]{2,3})+)*$/.test(
    value
  );
};

Vue.prototype.filterNumber = (evt) => {
  evt = evt ? evt : window.event;
  let expect = evt.target.value.toString() + evt.key.toString();

  if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
    evt.preventDefault();
  } else {
    return true;
  }
};

Vue.prototype.currencyFormat = (value) => {
  if (value === null) return false;
  return /^[0-9]+([.][0-9]{1,2})?$/.test(value);
};

Vue.prototype.isDui = (dui) => {
  if (dui === null) return false;
  let valido = false;

  // Validando la longitud del numero de DUI
  if (dui.length === 10) {
    // Validando que no sea ceros todos los dígitos
    if (dui !== "00000000-0") {
      // Obteniendo los dígitos y el verificador
      let [digitos, validador] = dui.split("-");

      // Verficiando que la cadena
      if (typeof digitos !== "undefined" && typeof validador !== "undefined") {
        // Verificando que el validador sea de un solo caracter
        if (validador.length === 1) {
          // Convirtiendo los digitos a array
          digitos = digitos.split("");

          // Convirtiendo los datos a tipo integer
          validador = parseInt(validador, 10);
          digitos = digitos.map((digito) => parseInt(digito, 10));

          // Obteniendo la suma corresponiente
          let suma = digitos.reduce(
            (total, digito, index) => (total += digito * (9 - index)),
            0
          );

          // Obteniendo el Modulo base 10
          let mod = suma % 10;
          mod = validador === 0 && mod === 0 ? 10 : mod;

          let resta = 10 - mod;

          if (resta === validador) {
            valido = true;
          }
        }
      }
    }
  }
  return valido;
};

Vue.prototype.isNit = (nit) => {
  if (nit === null) return false;

  let valido = false;

  // Validando la longitud del numero de DUI
  if (nit.length === 17) {
    // Validando que no sea ceros todos los dígitos
    if (
      nit !== "0000-000000-000-0" &&
      nit.match(/^[0-9]{4}-[0-9]{6}-[0-9]{3}-[0-9]$/)
    ) {
      // Obteniendo los dígitos y el verificador por separado
      let [ubicacion, fecha, correlativo, validador] = nit.split("-");
      let digitos = (ubicacion + fecha + correlativo).split("");

      // Convirtiendo los datos a tipo integer
      validador = parseInt(validador, 10);
      correlativo = parseInt(correlativo, 10);
      let suma = 0;
      let mod = 0;
      if (correlativo <= 100) {
        // Obteniendo la suma corresponiente
        suma = digitos.reduce(
          (total, digito, index) =>
            (total += parseInt(digito, 10) * (14 - index)),
          0
        );
        // Obteniendo el Modulo base 11
        mod = suma % 11;
        mod = mod === 10 ? 0 : mod;
      } else {
        // Obteniendo la suma corresponiente
        suma = digitos.reduce(
          (total, digito, index) =>
          (total +=
            parseInt(digito, 10) *
            parseInt(3 + 6 * Math.floor((index + 5) / 6) - (index + 1), 10)),
          0
        );
        // Obteniendo el Modulo base 11
        mod = suma % 11;
        mod = mod > 1 ? 11 - mod : 0;
      }
      if (mod === validador) {
        valido = true;
      }
    }
  }
  return valido;
};

Vue.prototype.getPaginationProperties = (response) => {
  return {
    page: Number(response.headers.page),
    per_page: Number(response.headers.per_page),
    total_rows: Number(response.headers.total_rows),
  };
};
//Funcion auxiliar para verificar paths accesibles
const buscarRuta = (rutas, ruta) => {
  return rutas.some((item) => {
    if (item?.childrens?.length > 0)
      if (buscarRuta(item?.childrens, ruta)) return true;

    return item.nombre_uri?.toLowerCase() === ruta.name.toLowerCase();
  });
};

const verificarRuta = (ruta, rutas = store.state.utils.rutas) => {
  return rutas.some((item) => item?.childrens?.length > 0
    ? verificarRuta(ruta, item?.childrens)
    : item.nombre_uri?.toLowerCase() === ruta?.toLowerCase()
  );
}

Vue.prototype.verificarRuta = verificarRuta;

//Funcion para verificar las paths accesibles
Vue.prototype.canNext = async (ruta) => {
  /*
  //Rutas a las que el usuario siempre va a poder acceder
  const permit = [
    "Forbidden",
    "security",
    "login",
    "identidad-digital",
    "recuperar-password",
    "reset-password",
    "2fa",
    "verifyMail",
    "register",
    "revisar-observaciones",
  ];
  //Si la ruta a ingresar esta definida en el arreglo local, permite ingreso
  if (permit.some((row) => row.toLowerCase() === ruta.name.toLowerCase()))
    return true;
  */

  if (!ruta.name) return false;

  if (ruta.meta.requiresAuth === false) return true

  //Se verifica si la ruta proporcionada se encuentra en las paths
  //almacenadas, si no hay entonces se hace la petición para almacenarlas
  let { utils } = store.state;
  if (utils.rutas && utils.rutas.length === 0) {
    Vue.prototype.showLoader()
    await store.dispatch("utils/getMenu").catch(() => {
      Vue.prototype.hideLoader()
      if(utils.global_status === 401){
        store.commit("utils/setGlobalStatus", null);
        return false
      }
    });
    Vue.prototype.hideLoader()
    const getU = localStorage.getItem('unidades');
    if (getU !== "false") await store.dispatch("getUnidades");
  }
  if (store.state.userInfo.roles)
    if (Vue.prototype.haveRole('ROLE_USER_ADMIN')) return true;
  //Busca ruta en el arreglo de paths
  return buscarRuta(utils.rutas, ruta);
};

Vue.filter("aMoneda", function (value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return formatter.format(value);
});

// Recibe una fecha en formato yyyy-mm-dd hh:mm:ss y la devuelve en formato hace x tiempo
Vue.prototype.fechaHaceTiempo = (fecha) => {
  let fechaActual = new Date();
  let fechaConsulta = new Date(fecha);
  let diferencia = fechaActual.getTime() - fechaConsulta.getTime();
  let segundos = Math.floor(diferencia / 1000);
  let minutos = Math.floor(segundos / 60);
  let horas = Math.floor(minutos / 60);
  let dias = Math.floor(horas / 24);
  let meses = Math.floor(dias / 30);
  let anios = Math.floor(meses / 12);
  if (anios > 0) {
    return "hace " + anios + " año" + (anios > 1 ? "s" : "");
  } else if (meses > 0) {
    return "hace " + meses + " mes" + (meses > 1 ? "es" : "");
  } else if (dias > 0) {
    return "hace " + dias + " dia" + (dias > 1 ? "s" : "");
  } else if (horas > 0) {
    return "hace " + horas + " hora" + (horas > 1 ? "s" : "");
  } else if (minutos > 0) {
    return "hace " + minutos + " minuto" + (minutos > 1 ? "s" : "");
  } else if (segundos > 0) {
    return "hace " + segundos + " segundo" + (segundos > 1 ? "s" : "");
  } else {
    return "hace 1 segundo";
  }
}

// Recibe un numero y lo devuelve en formato de moneda agregando los puntos y la coma y dos decimales (1,000.00)
Vue.prototype.mostrarFormatoMoneda = (valor, prefix = false) => {
  if (valor === null || valor === undefined) return "";
  valor = Number(valor);
  let valorFormato = valor.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  if (prefix) valorFormato = "$ " + valorFormato;
  return valorFormato;
}

Vue.prototype.mostrarFormatoMonedaSinDecimales = (valor, prefix = false) => {
  if (valor === null || valor === undefined) return "";
  valor = Number(valor);
  let valorFormato = valor.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  if (prefix) valorFormato = "$ " + valorFormato;
  return valorFormato;
}
Vue.prototype.getFechaFormated = (fecha) => {
  if (fecha === null || fecha === undefined) return "";
  else return moment(fecha).format("DD/MM/YYYY HH:mm ");

}
Vue.prototype.getFechaFormatedAM = (fecha) => {
  if (fecha === null || fecha === undefined) return "";
  else return moment(fecha).format("DD/MM/YYYY hh:mm A");

}
Vue.prototype.getDays = (fecha_inicio, fecha_fin) => {
  if (fecha_inicio === null || fecha_inicio === undefined) return "";
  else return moment(fecha_fin).diff(moment(fecha_inicio), "days");

}
Vue.prototype.shortText = (text) => {
  if (text === null || text === undefined) return "";
  else return text.substring(0, 40) + "...";
}

/**
* @description Funcion para formatear fecha
* @param {Date} date_param
* @returns {String} Fecha formateada
*/
Vue.prototype.FormatDatePrt = (date_param, time_param = false) => {
 if (date_param != null ) {
  if (time_param) {
    return moment(date_param).format("DD/MM/YYYY HH:mm a");
  } else {
    return moment(date_param).format("DD/MM/YYYY");
  }
 } else {
   return "";
 }
}

// Modulo convenio marco
Vue.prototype.pushAppMessage = async (message) => {
  await store.commit('utils/pushAppMessage', message);
}

Vue.prototype.dismissAppMessage = async (messageWithId) => {
  await store.commit('utils/dismissAppMessage', messageWithId);
}