import Proveedores from "../../../../services/Proveedores.services";

/**
 * Funcion para obtener los accionistas de un proveedor
 *
 * @param {*} context
 * @param {*} payload
 */
export const getShareholders = async ({ commit, state }, payload) => {
  const response = await Proveedores.getShareholderList({
    page: state.page,
    per_page: state.perPage,
    id_sociedad: payload?.id_sociedad,
    id_proveedor: payload?.id_proveedor
  });
  if (response.status === 200) {
    commit("setShareholders", response.data);
    commit("setTotalRows", Number(response.headers.total_rows));

    updateCurrentPercentage({ commit, state });
  }
};

/**
 * Funcion para eliminar un accionista de un proveedor
 *
 * @param {*} context
 * @param {*} payload
 */
export const deleteShareholder = async ({ commit, state }, payload) => {
  const response = await Proveedores.deleteShareholder(payload.id);

  if (response.status === 200) {
    const newShareholders = state.shareholders.filter(
      (shareholder) => shareholder.id !== payload.id
    );
    commit("setShareholders", newShareholders);

    updateCurrentPercentage({ commit, state });

    return true;
  }
};

/**
 * Funcion que actializa la suma de los porcentajes de los accionistas
 */
export const updateCurrentPercentage = ({ commit, state }) => {
  const currentePercentage = state.shareholders.reduce(
    (a, b) => a + b.porcentaje_participacion,
    0
  );

  commit("setCurrentPercentage", currentePercentage);
};

export const paginate = ({ commit, state }, payload) => {
  commit("setPage", payload.pagina);
  commit("setPerPage", payload.cantidad_por_pagina);

  getShareholders({ commit, state });
};
