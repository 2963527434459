import {http_client,} from "@/plugins/http_client";

const obtenerListado = async (params) => http_client(`api/v1/reporteria/director/subastas`, params);
const obtenerListadoInstituciones = async (params) => http_client(`api/v1/reporteria/director/instituciones`, params);

const proveedoresParticipandoSubasta = async (params) => http_client(`api/v1/reporteria/director/proveedores/con/participacion/subasta`, params);
const obtenerTotales = async (params) => http_client(`api/v1/reporteria/director/totales/subastas`, params);
const institucionesSubastas = async (params) => http_client(`api/v1/reporteria/director/instituciones/con/subastas`, params);
export default {
    obtenerListado,
    obtenerListadoInstituciones,
    proveedoresParticipandoSubasta,
    obtenerTotales,
    institucionesSubastas
}
