/**
 * Contiene los métodos para el consumo de los servicios de la API
 */
import { http_client } from "@/plugins/http_client";

/**
 * Retorna el listado de procesos PEO
 * @param {*} params Parámetros de la petición
 * @returns
 */
const getListadoProcesos = async (params = {}) => {
  return await http_client(
    "/api/v1/procesos/compra/evaluaciones/peo",
    params,
    "get"
  );
};

/**
 * Carga el acta de panel de evaluación
 *
 * @param {*} params Parámetros de la petición
 */
const cargarActaPanel = async (id_proceso, params = {}) => {
  try {
    return await http_client(
      `/api/v1/procesos/compra/${id_proceso}/evaluacion/documento`,
      params,
      "post"
    );
  } catch (error) {
    return error.response;
  }
};

/**
 * Descargar acta de panel de evaluación
 *
 * @param {*} id_proceso
 * @param {*} params
 */
const descargarActaPanel = async (id_proceso, params = {}) => {
  try {
    return await http_client(
      `/api/v1/procesos/compra/${id_proceso}/evaluacion/documento`,
      params,
      "get"
    );
  } catch (error) {
    return error.response;
  }
};

const getOfertasProcesos = async (id_proceso, id_proveedor, params = {}) => {
  try {
    return await http_client(
      `/api/v1/procesos/proveedores/${id_proveedor}/procesos/ofertas/${id_proceso}`,
      params,
      "get"
    );
  } catch (error) {
    return error.response;
  }
};

const getGanadoresProcesos = async (
  id_proceso,
  id_oferta = "",
  params = {}
) => {
  try {
    return await http_client(
      `/api/v1/compra/proceso/${id_proceso}/oferta-financiera/ganadores/${id_oferta}`,
      params,
      "get"
    );
  } catch (error) {
    return error.response;
  }
};

const saveGanadoresProcesos = async (id_proceso, params = {}) => {
  try {
    return await http_client(
      `/api/v1/compra/proceso/${id_proceso}/oferta-financiera/ganadores`,
      params,
      "post"
    );
  } catch (error) {
    return error.response;
  }
};

const updateGanadoresProcesos = async (id_proceso, params = {}) => {
  try {
    return await http_client(
      `/api/v1/compra/proceso/${id_proceso}/oferta-financiera/ganadores`,
      params,
      "put"
    );
  } catch (error) {
    return error.response;
  }
};

const postOfertaTecnica = async (
  id_proceso,
  id_oferta_tecnica,
  params = {}
) => {
  try {
    return await http_client(
      `/api/v1/compra/proceso/${id_proceso}/oferta-tecnica/${id_oferta_tecnica}/ganadores`,
      params,
      "post"
    );
  } catch (error) {
    return error.response;
  }
};

const getGanadoresOfertaTecnica = async (id_proceso, params = {}) => {
  try {
    return await http_client(
      `/api/v1/compra/proceso/${id_proceso}/oferta-tecnica/participantes`,
      params,
      "get"
    );
  } catch (error) {
    return error.response;
  }
};

const getGanadorOfertaTecnica = async (
  id_proceso,
  id_oferta_tecnica,
  params = {}
) => {
  try {
    return await http_client(
      `/api/v1/compra/proceso/${id_proceso}/oferta-tecnica/${id_oferta_tecnica}/ganadores`,
      params,
      "get"
    );
  } catch (error) {
    return error.response;
  }
};

const finalizarAdjudicacion = async (id_proceso, id_tipo_oferta, params = {}) => {
  try {
    return await http_client(
      `/api/v1/ofertas/proceso/${id_proceso}/finalizar-adjudicacion/${id_tipo_oferta}`,
      params,
      "put"
    );
  } catch (error) {
    return error.response;
  }
};

const finalizarEvaluacion = async (id_proceso, id_tipo_oferta, params = {}) => {
  return await http_client(
    `/api/v1/ofertas/proceso/${id_proceso}/finalizar-evaluacion/${id_tipo_oferta}`,
    params,
    "put"
  );
};

const eliminarEvaluacion = async (id_proceso, id_participacion_proveedor) => {
  return await http_client(
    `/api/v1/ofertas/proceso/${id_proceso}/eliminar-evaluacion/${id_participacion_proveedor}`,
    {},
    "delete"
  );
};

export default {
  getListadoProcesos,
  cargarActaPanel,
  descargarActaPanel,
  getOfertasProcesos,
  getGanadoresProcesos,
  saveGanadoresProcesos,
  updateGanadoresProcesos,
  postOfertaTecnica,
  getGanadoresOfertaTecnica,
  getGanadorOfertaTecnica,
  finalizarAdjudicacion,
  finalizarEvaluacion,
  eliminarEvaluacion,
};
