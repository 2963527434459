<template>
  <Fragment>
    <v-row no-gutters>
      <v-col>
        <v-tabs v-model="tab"  background-color="bgMinsal" :vertical="$vuetify.breakpoint.smAndDown" active-class="bgMinsal" center-active flat>
          <v-tab v-for="(item, index) in items" :key="index" class="secondary--text">
            {{ item.nombre }}
          </v-tab>
          <v-tabs-slider color="secondary" />
        </v-tabs>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(item, index) in items" :key="index">
            <slot name="contenido" :item="item"></slot>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </Fragment>
</template>
<script>
export default {
  name: "AppTabComponent",
  data: () => ({
    tab: null
  }),
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
}
</script>
