import { http_client_with_status } from "@/plugins/http_client"
import {injectParameter} from "@/utils/text";

const getProducts = async(filters) => await http_client_with_status('/api/v1/cm-producto', filters);

const getProductsByFamily = async (familyId, filters) => await http_client_with_status(`/api/v1/catalogo-en-linea/productosPorFamilia/${familyId}`, filters)

const getProductById = async(productId, requestId) => await http_client_with_status(injectParameter('api/v1/catalogo-en-linea/producto/detalle',productId), requestId);

const getOffersByProductId = async(productId, filters, idSolicitud) => 
    await http_client_with_status(injectParameter(`api/v1/catalogo-en-linea/producto/ofertas/${productId}`,idSolicitud), filters);

const getProductTypes = async() => await http_client_with_status('api/v1/cm-producto/tiposDeProductos');

const upsertProduct = async(product, productId) => {
    let url = 'api/v1/cm-producto';
    const method = productId ? 'PUT' : 'POST';

    if (productId) url = url + `/${productId}`;

    return await http_client_with_status(url, product, method, {
        'Content-Type': 'multipart/form-data',
    });
}

const deleteProduct = async(productId) => await http_client_with_status(`api/v1/cm-producto/${productId}`, null, 'DELETE');

const toggleStatus = async(productId) => await http_client_with_status(`api/v1/cm-producto/activo/${productId}`, null, 'PUT');

const getProductFunds = async(productId) => await http_client_with_status(`api/v1/catalogo-en-linea/producto/fondos/${productId}`, null, 'GET');

const getProductFundsEdit = async(productId, requestId) => await http_client_with_status(`api/v1/catalogo-en-linea/producto/fondos/editar/${productId}/${requestId}`, null, 'GET');

const getFundAmounts = (fundId,productId) => http_client_with_status(
    `api/v1/catalogo-en-linea/producto/fondos/disponible/${fundId}${productId ? '/' + productId : ''}`,
    null, 
    'GET',
);
const obtenerAsignacionPresupuestaria = () => http_client_with_status(`api/v1/catalogo-en-linea/asignacion/presupuestaria`, null, 'GET');

const auditoriaCatalogo = async (id_proceso, params = {}) => http_client_with_status(`api/v1/catalogo-en-linea/auditoria-catalogo/${id_proceso}`, params, 'get');

export default {
    getProductsByFamily,
    getProductById,
    getOffersByProductId,
    getProducts,
    getProductTypes,
    upsertProduct,
    deleteProduct,
    toggleStatus,
    getProductFunds,
    getProductFundsEdit,
    getFundAmounts,
    obtenerAsignacionPresupuestaria,
    auditoriaCatalogo,
};
