<template>
  <v-row :no-gutters="noGutters" :dense="dense">
    <v-col cols="12">
      <div
        class="d-block text-center text-sm-start mb-3 mb-sm-1"
        v-if="table_title != ''"
      >
        <span class="ml-1 text-wrap">
          {{ table_title || "" }}
        </span>
      </div>
      <v-data-table
        :loading="show_loading"
        :headers="headers"
        :items="items"
        hide-default-footer
        loading-text="Obteniendo datos..."
        :items-per-page="select"
        @update:options="sortFunction"
        :disable-pagination="inhabilitar_paginacion"
        :mobile-breakpoint="mobile_breakpoint"
        :itemKey="itemKey"
      >
        <!-- Exponemos los slots del v-data-table en el componente padre -->
        <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
          <slot :name="name" v-bind="data"></slot>
        </template>
        <!-- Agregar botones de acciones de forma dinamica -->
        <template v-if="show_actions" v-slot:[`item.actions`]="{ item }">
          <slot
            :name="ac.eventName"
            v-for="ac in actions"
            v-bind="{ ac, item }"
          >
            <DataTableButton
              :key="ac.eventName"
              :icon="ac.icon.name"
              :icon-color="ac.icon.color"
              :tooltipText="ac.tooltip.text"
              :tooltipColor="ac.tooltip.color"
              @click="$emit(ac.eventName, item)"
            />
          </slot>
        </template>

        <!-- Paginacion personalizada -->
        <template v-if="tiene_paginacion" v-slot:[`footer`]>
          <slot name="pagination">
            <v-row class="px-4 d-flex justify-space-between" :no-gutters="noGutters" :dense="dense">
              <v-col
                cols="12"
                lg="3"
                sm="5"
                align-self="center"
                class="pb-0 d-flex align-center"
              >
                <span align-self="center" class="ml-4 pagination-select-text"
                  >Mostrando:
                </span>
                <v-select
                  class="pagination-select-text data-table-select ml-4"
                  @change="changeItemsPerPage"
                  v-model="select"
                  :items="registros_por_pagina"
                >
                </v-select>
                <span align-self="center" class="ml-5 pagination-select-text"
                  >Resultados</span
                >
              </v-col>
              <!-- Total de registros -->
              <v-col
                cols="12"
                lg="2"
                align-self="center"
                class="pb-0 d-sm-none d-none d-lg-flex justify-center pr-0"
                ><p
                  v-if="mostrar_cantidad"
                  class="text--gray mt-0 mt-sm-5"
                  style="font-size: 14px; font-weight: 500 !important"
                >
                  {{ elementos_mostrados }} registros de {{ total_registros }}
                </p></v-col
              >
              <v-col cols="12" sm="5" lg="4" align-self="center" class="pb-0">
                <div class="d-flex justify-end">
                  <v-pagination
                    @input="changePage"
                    :length="cantidad_paginas"
                    v-model="pagina"
                    total-visible="6"
                  ></v-pagination>
                </div>
              </v-col>
            </v-row>
          </slot>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>
<script>
import DataTableButton from "./DataTableButton.vue";
export default {
  name: "DataTableComponent",
  components: {
    DataTableButton,
  },
  models: [
    { data: "select", event: "models:select" },
    { data: "pagina", event: "models:pagina" },
  ],
  props: {
    custom_item_per_page:{
      type: Number,
      default: null,
    },
    registros_por_pagina: {
      type: Array,
      default: () => [5, 10, 25, 50, 100, 500, 1000, 2000],
    },

    total_registros: {
      type: Number,
      default: 0,
    },
    mostrar_cantidad: {
      type: Boolean,
      default: true,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    tiene_paginacion: {
      type: Boolean,
      default: true,
    },
    inhabilitar_paginacion: {
      type: Boolean,
      default: false,
    },
    show_actions: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Array,
      required: false,
      default() {
        return [
          // Estructura de las acciones dinamicas
          // {
          //   tooltip: {
          //     text: "Enviar Notificación",
          //     color: "gtPrimary",
          //   },
          //   icon: {
          //     color: "gtPrimary",
          //     name: "mdi-send-check",
          //   },
          //   eventName: "send",
          // },
          // {
          //   tooltip: {
          //     text: "Ver",
          //     color: "gtPrimary",
          //   },
          //   icon: {
          //     color: "gtPrimary",
          //     name: "mdi-eye",
          //   },
          //   eventName: "show",
          // },
        ];
      },
    },
    mobile_breakpoint: {
      type: String,
    },
    show_loading: {
      type: Boolean,
      default: false,
    },
    table_title: {
      type: String,
      default: "",
    },
    dense: {
      type: Boolean,
      default: false,
    },
    noGutters: {
      type: Boolean,
      default: false,
    },
    itemKey: {
      type: String,
    },
  },
  data: () => ({
    filtros: {},
    select: 10,
    pagina: 1,
  }),
  computed: {
    elementos_mostrados() {
      const registros = this.select * this.pagina;
      if (registros <= this.total_registros) return registros;
      else return this.total_registros;
    },
    cantidad_paginas() {
      return Math.ceil(this.total_registros / this.select);
    },
  },
  methods: {
    //función para cambiar manualmente el valor de los items por página
    setItemPerPage(value) {
      this.select = value;
    },
    // Función que emite el evento de cambio de pagina
    changePage(e) {
      this.filtros = {
        cantidad_por_pagina: this.select,
        pagina: e,
      };

      this.$emit("paginar", this.filtros);
    },
    changeItemsPerPage(e) {
      this.pagina = 1;
      this.filtros = {
        cantidad_por_pagina: e,
        pagina: this.pagina,
      };

      this.$emit("paginar", this.filtros);
    },
    sortFunction(sortableInfo) {
      this.$emit("sortFunction", sortableInfo);
    },
  },
  mounted() {
    if (!this.tiene_paginacion && this.custom_item_per_page > 0) {
      this.select = this.custom_item_per_page;
    }
  }
};
</script>
<style lang="scss" scoped>
:deep(.v-chip) {
  width: -webkit-fill-available !important;
  display: flex !important;
  justify-content: center !important;
}

// Alinea todos los encabezados del componente
:deep(
    .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > thead
      > tr:last-child
      > th.sortable
  ) {
  text-align: center !important;
}
:deep(
    .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > thead
      > tr:last-child
      > th
  ) {
  text-align: center !important;
}
</style>
