import { http_client, http_client_with_status } from "@/plugins/http_client";

const listProductos = async (params = {}) => {
    return await http_client(`/api/v1/cm-producto`, params, "get");
};

const listAtributosProducto = async (id,params = {}) => {
    return await http_client(`/api/v1/cm-producto/atributosDelProducto/${id}`, params, "get");
};
const fetchSubProcesoAtributoProducto = async (id,params = {}) => {
    return await http_client_with_status(`/api/v1/cm-producto/subgrupo/atributo/producto/${id}`, params, "get");
};
const obtenerProducto = async (id,params = {}) => {
    return await http_client_with_status(`/api/v1/cm-producto/obtener/producto/${id}`, params, "get");
};

const obtenerPrecioProducto = async (id, params = {}) => {
    return await http_client_with_status(`/api/v1/cm-producto/obtener/precio/producto/${id}`, params, "get");
}
    
const deletePrecioProducto = async(id) => await http_client_with_status(`api/v1/cm-producto/eliminar/${id}`, null, 'DELETE');

const actualizarPrecioProducto = async(id, params) => await http_client_with_status(`api/v1/cm-producto/actualizar/${id}`, params, 'PUT');

const listProductoProveedor = async (id, params = {}) => {
    let urlBase = '/api/v1/cm-producto/obtener/producto/proveedor';
    if (id) {
     urlBase+= `/${id}`;
   }
   return await http_client_with_status(urlBase , params, "get");
}
const crearOfertaProducto = async (body = {}) => {
    return await http_client_with_status(`/api/v1/cm-producto/crear/oferta/producto`, body, "POST");
}
// Direccion

const agregarOfertaDireccion = async (body = {}) => {
    return await http_client_with_status(`/api/v1/cm-producto/crear/oferta/direccion`, body, "POST");
}
const obtenerOfertaDireccion = async (id, params = {}) => {
    return await http_client_with_status(`/api/v1/cm-producto/obtener/oferta/direccion/${id}`, params, "get");
}
const deleteDireccion = async(id) => await http_client_with_status(`api/v1/cm-producto/eliminar/direccion/${id}`, null, 'DELETE');


// Region
const agregarOfertaRegion = async (body = {}) => {
    return await http_client_with_status(`/api/v1/cm-producto/crear/oferta/region`, body, "POST");
}
const obtenerOfertaRegion = async (id, params = {}) => {
    return await http_client_with_status(`/api/v1/cm-producto/obtener/oferta/region/${id}`, params, "get");
}
const deleteRegion = async(id) => await http_client_with_status(`api/v1/cm-producto/eliminar/region/${id}`, null, 'DELETE');


const cargarProductoSubGrupo = async (id, params= {}) => {
    return await http_client_with_status(`/api/v1/cm-producto/producto/subgrupo/atributo/${id}`, params, "get");
};

const obtenerUnidadesMedida = async (id, params= {}) => {
    return await http_client_with_status(`/api/v1/cm-producto/producto/unidad`, params, "get");
};

const cargarOfertasExcel = async (params= {}) => {
    return await http_client_with_status(`/api/v1/cm-producto/cargar/productos`, params, "post");
};

const descargarKardexPrecio = () => 
    http_client_with_status(`/api/v1/cm-producto/descargar/kardex-precio`, null, 'get', {}, { responseType: 'blob' },
);

const descargarKardexProducto = () => 
    http_client_with_status(`/api/v1/cm-producto/descargar/kardex-producto`, null, 'get', {}, { responseType: 'blob' },
);


const guardarConfiguracionGastoAdministrativo = (idOferta,params) => 
    http_client(`/api/v1/cm-producto/guardar-configuracion/${idOferta}`, params, 'post',
);
const listarConfiguracionGastosAdministrativos = (idOferta,params) => 
    http_client(`/api/v1/cm-producto/listar-configuracion/${idOferta}`, params, 'get',
);
// const actualizarConfiguracionGastosAdministrativos = (idGasto, idOferta, params) => 
//     http_client(`/api/v1/cm-producto/listar-configuracion/${idGasto}/${idOferta}`, params, 'get',
// );
const eliminarConfiguracionGastosAdministrativos = (idGasto,params) => 
    http_client(`/api/v1/cm-producto/eliminar-configuracion/${idGasto}`, params, 'delete',
);

const listaSkuGastosAdministrativos = (params) => 
    http_client(`/api/v1/cm-producto/listar-sku`, params, 'get',
);

const cargarOfertasSKU = (params) => 
    http_client(`/api/v1/cm-producto/listar-ofertas`, params, 'get',
);



export default {
  listProductos,
    listAtributosProducto,
    fetchSubProcesoAtributoProducto,
    obtenerProducto,
    obtenerPrecioProducto,
    deletePrecioProducto,
    actualizarPrecioProducto,
    listProductoProveedor,
    crearOfertaProducto,
    agregarOfertaDireccion,
    obtenerOfertaDireccion,
    deleteDireccion,
    agregarOfertaRegion,
    obtenerOfertaRegion,
    deleteRegion,
    cargarProductoSubGrupo,
    obtenerUnidadesMedida,
    cargarOfertasExcel,
    descargarKardexPrecio,
    descargarKardexProducto,
    guardarConfiguracionGastoAdministrativo,
    listarConfiguracionGastosAdministrativos,
    eliminarConfiguracionGastosAdministrativos,
    listaSkuGastosAdministrativos,
    cargarOfertasSKU,
    // actualizarConfiguracionGastosAdministrativos
}
