export default () => ({
  procesoData: {},
  participando: false,
  showModalAgregarLote: false,
  showModalAddEspecificacion: false,
  showModalDeleteLote: false,
  idLoteAccion: null,
  datosLoteSeleccionado: {
    nombre: null,
    descripcion: null,
  },
  lotes: [],

  selectedLot: null,
  offers: [],
  offer: {},
  fecha_resolucion: null,
  exist_solicitud: false,

  // auditoria contrato
  list_contrato_st: [],
});