import ContratoServices from "../../../../services/Contrato.services";
import moment from "moment-timezone";
import Vue from "vue";
export const getContrato = async ({ commit }, payload) => {
  Vue.prototype.showLoader()
  const response = await ContratoServices.getContrato(payload);
  commit("obtenerContrato", response.data);
  // if (response.status === 200) {
  //   setTimeout(() => {
  //     Vue.prototype.hideLoader()
  //   }, 2500)
  // }
};

export const getEntregaContrato = async ({ commit }, payload) => {
  const response = await ContratoServices.getEntrega(payload);

  if (response.status === 200) {
    const { calificacion, movimiento, listo, acta_recepcion } = response.data;

    if (calificacion || movimiento) {
      let entrega = {
        monto: Number(movimiento?.monto),
        monto_inicial: Number(movimiento?.monto_etapa),
        fecha_hora_movimiento: moment(movimiento?.fecha_hora_movimiento).format('YYYY-MM-DD HH:mm:ss'),
        justificacion: null,
        id_tipo_movimiento: movimiento?.id_movimiento,
        id_metodo_pago: movimiento?.detalle?.id_metodo_pago,
        // id_institucion_financiera:
        //   movimiento?.detalle[0]?.id_institucion_financiera,
        numero_cheque: movimiento?.detalle?.numero_cheque,
        a_nombre_de: movimiento?.detalle?.a_nombre_de,
        ponderacion: Number(calificacion?.ponderacion),
        observaciones: calificacion?.observaciones,
        acta_recepcion,
        documento: null,
        comprobante: null,
      };
      commit("getEntregaData", entrega);
      commit("setEntregaEditable", true);
      commit("setEntregaLista", listo);
    }
  }
};

export const getPagos = async ({ commit, state }, { idContrato, filtros }) => {
  if (!filtros) filtros = state.filtrosPagos;
  const { status, data, headers } = await ContratoServices.getContratoPagos(
    idContrato,
    filtros
  );
  if (status == 200) commit("setPagos", { data, headers });
};

export const getSansiones = async (
  { commit, state },
  { selectProvider, filtros }
) => {
  if (!filtros) filtros = state.filtrosSanciones;
  const { status, data, headers } = await ContratoServices.getSancionesList(
    selectProvider,
    filtros
  );
  if (status === 200) commit("setSanciones", { status, data, headers });
  return { status, data, headers };
};
