import { http_client, http_client_with_status } from "@/plugins/http_client";

const getPacProcesos = async (params = {}) => {
  return await http_client("/api/v1/pac/procesos", params, "get");
};

const getSolicitudObs = async (params = {}) => {
  return await http_client("/api/v1/pac/solicitudes/", params, "get");
};

const saveProcesos = async (params = {}) => {
  return await http_client("/api/v1/pac/procesos", params, "post");
};

const getSolicitudesAsignadasPAC = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `/api/v1/procesos/${id_proceso_compra}/solicitudes`,
    params,
    "get"
  );
};

const getSolicitudesParaAsignar = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `/api/v1/procesos/${id_proceso_compra}/solicitudes/pendientes`,
    params,
    "get"
  );
};

const asignarSolicitudesPAC = async (
  id_proceso_compra,
  id_solicitud_compra_obs,
  params = {}
) => {
  return await http_client(
    `/api/v1/procesos/${id_proceso_compra}/solicitudes/${id_solicitud_compra_obs}`,
    params,
    "post"
  );
};

const eliminarSolicitudesPAC = async (
  id_proceso_compra,
  id_solicitud_compra_obs,
  id_detalle_solicitud,
  params = {}
) => {
  return await http_client(
    `/api/v1/procesos/${id_proceso_compra}/solicitudes/${id_solicitud_compra_obs}/detalles/${id_detalle_solicitud}`,
    params,
    "delete"
  );
};

const getProceso = async (id_proceso_compra) => {
  return await http_client(`/api/v1/compra/proceso/${id_proceso_compra}`, {});
};

const postProcesoCompra = async (params = {}) => {
  return await http_client(`/api/v1/compra/proceso`, params, "post");
};

const deleteProcesoCompra = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}`,
    params,
    "delete"
  );
};

const getProcesoEtapasActivas = async (id_proceso_compra) => {
  return await http_client(
    `/api/v1/compras/procesos/${id_proceso_compra}/etapas`,
    {}
  );
};

const actualizarProceso = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}`,
    params,
    "put"
  );
};

const actualizarProcesoEtapa = async (
  id_proceso_compra,
  id_etapa,
  params = {}
) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/etapa/${id_etapa}`,
    params,
    "put"
  );
};

const getProcesoModalidad = async (params = {}) => {
  return await http_client(`/api/v1/compra/proceso/modalidades`, params, "get");
};

const postNuevaModalidad = async (params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/modalidades`,
    params,
    "post"
  );
};

const getModalidad = async (id_modalidad, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/modalidades/${id_modalidad}`,
    params,
    "get"
  );
};

const putModalidad = async (id_modalidad, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/modalidades/${id_modalidad}`,
    params,
    "put"
  );
};

const postNuevoHito = async (id_forma_contratacion, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/modalidades/${id_forma_contratacion}/hitos`,
    params,
    "post"
  );
};

const actualizarHito = async (id_hito, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/modalidades/hitos/${id_hito}`,
    params,
    "put"
  );
};

const postParticipanteModalidad = async (id_hito, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/modalidades/hitos/${id_hito}/participantes`,
    params,
    "post"
  );
};

const putParticipanteModalidad = async (id_participante, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/modalidades/hitos/participantes/${id_participante}`,
    params,
    "put"
  );
};

const getHito = async (id_hito, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/modalidades/hitos/${id_hito}`,
    params,
    "get"
  );
};

const guardarContenidoEtapa = async (id_hito, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/modalidades/hitos/${id_hito}/contenido`,
    params,
    "put"
  );
};

const guardarDocumentosProceso = async (params = {}, onUploadProgress) => {
  return await http_client(
    `/api/v1/documentos/cargar`,
    params,
    "post",
    {},
    true,
    "json",
    true,
    onUploadProgress
  );
};

const descargarDocumentoProceso = async (params = {}) => {
  return await http_client(
    `/api/v1/documentos/descargar`,
    params,
    "get",
    {},
    true,
    "blob",
    false
  );
};

const getDocumentoProceso = async (params = {}) => {
  return await http_client(
    `/api/v1/documentos/descargar`,
    params,
    "get",
    {},
    true,
    "arrayBuffer"
  );
};

const obtenerDocumentosProceso = async (
  id_proceso_compra,
  id_tipo_doc,
  params = {}
) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/documentos/tipos/${id_tipo_doc}`,
    params
  );
};

const obtenerDocumentosProcesoProvider = async (
  id_proceso_compra,
  id_tipo_doc,
  params = {}
) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/documentos/tipos/${id_tipo_doc}/proveedores`,
    params
  );
};

const eliminarDocumentoProceso = async (
  id_proceso_compra,
  id_doc,
  params = {}
) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/documentos/${id_doc}`,
    params
  );
};

const getProveedoresParaProceso = async (params = {}) => {
  return await http_client(`/api/v1/compra/proceso/proveedores`, params);
};

const saveGanadorProceso = async (id_proceso, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso}/oferta-financiera/ganadores`,
    params,
    "post"
  );
};

const getParticipantesGanadores = async (id_proceso, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso}/participantes/oferta-financiera/ganadores`,
    params
  );
};

const getGanadoresProcesos = async (id_proceso, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso}/oferta-financiera/ganadores`,
    params
  );
};

const actualizarEtapaProceso = async (
  id_proceso_compra,
  id_etapa,
  params = {}
) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/etapa/${id_etapa}`,
    params,
    "put"
  );
};

const cambiarOrdenHitos = async (id_hito, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/hitos/${id_hito}/orden`,
    params,
    "put"
  );
};

const eliminarPerfilesAutorizados = async (id_perfil, params) => {
  return await http_client(
    `/api/v1/compra/proceso/modalidades/hitos/participantes/${id_perfil}`,
    params,
    "delete"
  );
};

const eliminarHito = async (id_hito, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/modalidades/hitos/${id_hito}`,
    params,
    "delete"
  );
};

const publicarProceso = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `/api/v1/procesos/compra/${id_proceso_compra}/publicar/simplificado`,
    params,
    "put"
  );
};

const cambioEstadoProcesoCompra = async (
  id_proceso_compra,
  id_siguiente_estado,
  params = {}
) => {
  return await http_client(
    `/api/v1/procesos/compra/${id_proceso_compra}/estado/${id_siguiente_estado}`,
    params,
    "put"
  );
};

const getEtapaSolicitudes = async (
  id_proceso_compra,
  id_tipo_etapa,
  params = {}
) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/etapa/tipo/${id_tipo_etapa}`,
    params,
    "get"
  );
};

const emitirCdp = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/cdp`,
    params,
    "post"
  );
};

const inscripcionProceso = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/inscripcion`,
    params,
    "post"
  );
};

const validarParticipacion = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/validar/inscripcion`,
    params
  );
};

// interes presidencia
const getListDocuments = async (id_proceso, params = {}) =>
  await http_client(`/api/v1/compra/proceso/${id_proceso}/documentos`, params);

const estadoDocumentoProveedor = (id, params) =>
  http_client_with_status(
    `/api/v1/compra/proceso/visibilidad/proveedores/${id}`,
    params,
    "put"
  );

const enviarDocumentoProveedor = (id, idProceso, params) =>
  http_client_with_status(
    `/api/v1/compra/proceso/enviar/correo/proveedores/${id}/${idProceso}`,
    params,
    "put"
  );

const deleteDocumentoProceso = (idDocumento) =>
  http_client(`/api/v1/documentos/eliminar/${idDocumento}`, null, "delete");

const getListTipoDocumento = async (id_proceso, filters = {}) =>
  await http_client(
    `/api/v1/compra/proceso/${id_proceso}/tipo/documentos`,
    filters
  );

const getTipoDocumentoProceso = async (idProceso, params = {}) =>
  await http_client(
    `/api/v1/compra/proceso/lista/documentos/tipo/${idProceso}`,
    params
  );
const getEvaluacionPeo = async (idProceso, params = {}) =>
  await http_client(
    `/api/v1/compra/proceso/evaluacion/peo/${idProceso}`,
    params
  );
const getListadoSubastas = async (idProceso, params = {}) =>
  await http_client(
    `/api/v1/compra/proceso/listado/subastas/${idProceso}`,
    params
  );

const getSupervisores = async (id_proceso, params = {}) =>
  await http_client(
    `/api/v1/compra/proceso/${id_proceso}/interes/presidente/supervisor`,
    params
  );
const getSearchedSupervisores = async (params = {}) =>
  await http_client(`/api/v1/usuarios/search`, params);

const postSupervisor = async (id_proceso, body) =>
  await http_client(
    `/api/v1/compra/proceso/${id_proceso}/interes/presidente/supervisor`,
    body,
    "post"
  );
const deleteSupervisor = async (id_proceso, id_supervisor) =>
  await http_client(
    `/api/v1/compra/proceso/${id_proceso}/interes/presidente/supervisor/${id_supervisor}`,
    {},
    "delete"
  );
const markInteresPresidencia = async (id_proceso, body) =>
  await http_client(
    `/api/v1/compra/proceso/${id_proceso}/interes/presidente`,
    body,
    "put"
  );
const unmarkInteresPresidencia = async (id_proceso, body) =>
  await http_client(
    `/api/v1/compra/proceso/${id_proceso}/interes/presidente/desmarcar`,
    body,
    "put"
  );

const closeEtapaProceso = async (id_proceso_compra, id_etapa, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/etapa/${id_etapa}/finalizar`,
    params,
    "put"
  );
};

const getConsultasProceso = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `/api/v1/procesos/compra/${id_proceso_compra}/consultas`,
    params,
    "get"
  );
};

const getConsultasProcesoAuditoria = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `/api/v1/procesos/compra/${id_proceso_compra}/auditoria/proceso/consultas`,
    params,
    "get"
  );
};

const obtenerLotesConfigurados = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/lotes`,
    params
  );
};

const storeLoteProceso = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/lotes`,
    params,
    "post"
  );
};

const obtenerDatosLote = async (id_lote, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/lotes/${id_lote}`,
    params,
    "get",
    {},
    true,
    "json",
    false,
    null,
    false,
    false
  );
};

const actualizarDatosLote = async (id_lote, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/lotes/${id_lote}`,
    params,
    "put"
  );
};

const separarAgrupador = async (id_lote, params = {}) =>
  http_client(`/api/v1/compra/proceso/lotes/separar/agrupador/${id_lote}`, params, "put");

const separarEspecifica = async (id_lote, params = {}) =>
  http_client(`/api/v1/compra/proceso/lotes/separar/especifica/${id_lote}`, params, "put");

const deleteLote = async (id_lote, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/lotes/${id_lote}`,
    params,
    "delete"
  );
};

const postProviderOffer = async (idProceso, params) =>
  await http_client(`/api/v1/ofertas/proceso/${idProceso}`, params, "post");

const getProviderOffers = async (idProceso, params) =>
  await http_client(`/api/v1/ofertas/proceso/${idProceso}/proveedor`, params);

const getProviderOffer = async (
  idProceso,
  idOferta,
  idParticipacionProveedor,
  params
) =>
  await http_client(
    `/api/v1/ofertas/proceso/${idProceso}/oferta/${idOferta}/${idParticipacionProveedor}`,
    params
  );

const getProcessOffers = async (idProceso, params) =>
  await http_client(`/api/v1/ofertas/proceso/${idProceso}`, params);

const creacionSala = async (id_proceso, params = {}) => {
  return await http_client(
    `/api/v1/procesos/${id_proceso}/salas`,
    params,
    "post"
  );
};

const getSalas = async (id_proceso, params = {}) => {
  return await http_client(
    `/api/v1/procesos/${id_proceso}/salas`,
    params,
    "get"
  );
};

const addSubasta = async (id_sala, params = {}) => {
  return await http_client(`/api/v1/procesos/salas/${id_sala}`, params, "post");
};

const removeSala = async (id_sala) =>
  await http_client(`/api/v1/procesos/salas/${id_sala}`, {}, "delete");

const getSubastasSala = async (id_sala, params = {}) => {
  return await http_client(`/api/v1/procesos/salas/${id_sala}`, params, "get");
};

const getSalasProceso = async (id_proceso, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso}/salas`,
    params,
    "get",
    {},
    true,
    "json",
    false,
    null,
    false,
    false
  );
};
const deleteProcessDocument = async (id_documento_proceso) => {
  return await http_client(
    `/api/v1/compra/proceso/documento/${id_documento_proceso}`,
    {},
    "delete"
  );
};

const putProcesoDesierto = async (id_proceso_compra, body = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/desierto`,
    body,
    "put"
  );
};

const postProcesoDesierto = async (id_proceso_compra, body = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/desierto`,
    body,
    "post"
  );
};

const estadoEtapaProceso = async (
  id_proceso_compra,
  id_tipo_oferta,
  params = {}
) =>
  http_client(
    `/api/v1/ofertas/proceso/${id_proceso_compra}/oferta/tipo/${id_tipo_oferta}/estado`,
    params,
    "get",
    {},
    true,
    "json",
    false,
    null,
    false,
    false
  );

const getGanadorSubasta = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `/api/v1/subasta/ganadores/proceso/${id_proceso_compra}`,
    params
  );
};

const editOfertaProveedor = async (id_oferta_proveedor, params = {}) => {
  return await http_client(
    `/api/v1/ofertas/proceso/oferta/${id_oferta_proveedor}`,
    params,
    "put"
  );
};

const getDocumentosProveedor = async (id_proceso, params = {}) => {
  return await http_client(
    `/api/v1/documentos/proveedor/proceso/${id_proceso}/documento`,
    params,
    "get",
    {},
    true,
    "blob"
  );
};

const guardarDocumentosProcesoEtapa = async (params = {}) => {
  return await http_client(
    `/api/v1/documentos/cargar/aclaracion-enmienda`,
    params,
    "post"
  );
};

const putSalaSubasta = async (id_subasta, params = {}) => {
  return await http_client(
    `/api/v1/procesos/subastas/${id_subasta}`,
    params,
    "put"
  );
};

const deleteSalaSubasta = async (id_subasta, params = {}) => {
  return await http_client(
    `/api/v1/procesos/subastas/${id_subasta}`,
    params,
    "delete"
  );
};

const getSubastaID = async (id_subasta, params = {}) => {
  return await http_client(
    `/api/v1/procesos/subastas/${id_subasta}`,
    params,
    "get"
  );
};

const getSalaDatos = async (id_sala, params = {}) => {
  return await http_client(
    `/api/v1/procesos/salas/${id_sala}/datos`,
    params,
    "get"
  );
};

const suspenderProceso = async (id_proceso_compra, body) => {
  return http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/suspender`,
    body,
    "put"
  );
};

const reanudarProceso = async (id_proceso_compra, body) => {
  return http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/reactivar`,
    body,
    "put"
  );
};

const getTipoHitos = async (params = {}) => {
  return http_client(`/api/v1/tipo-hitos`, params, "get");
};

const getDocumentoProcesoBuffer = async (params = {}) => {
  return await http_client(
    `/api/v1/documentos/buffer/`,
    params,
    "get",
    {},
    true,
    "arraybuffer"
  );
};

const getEtapaActualProcesoImpugnacion = async (
  id_proceso_compra,
  params = {}
) => {
  return http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/impugnaciones/etapa-actual`,
    params,
    "get"
  );
};

const getEtapasProcesoImpugnacion = async (
  id_proceso_compra,
  id_etapa_proceso,
  params = {}
) => {
  return http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/impugnaciones/etapas/${id_etapa_proceso}`,
    params,
    "get"
  );
};

const getDetailImpugnacion = async (id_impugnacion, params = {}) => {
  return http_client(
    `/api/v1/compra/proceso/impugnaciones/${id_impugnacion}`,
    params,
    "get"
  );
};

const getDetailEtapa = async (id_proceso, id_etapa, params = {}) => {
  return http_client(
    `/api/v1/compra/proceso/${id_proceso}/impugnaciones/etapa/${id_etapa}`,
    params,
    "get"
  );
};

const actualizarHitosProceso = async (id_proceso_compra, body) => {
  return http_client(
    `/api/v1/compra/proceso/actualizar/configuracion/${id_proceso_compra}`,
    body,
    "put"
  );
};

const getCantidadOfertasProceso = async (id_proceso_compra, params = {}) => {
  return http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/cantidad-ofertas`,
    params,
    "get"
  );
};

const cancelarProceso = async (id_proceso_compra, body) => {
  return http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/cancelar`,
    body,
    "put"
  );
};

const deleteOfertaProveedor = async (
  id_proceso_compra,
  id_oferta,
  params = {}
) => {
  return http_client(
    `/api/v1/ofertas/proceso/${id_proceso_compra}/oferta/${id_oferta}`,
    params,
    "delete"
  );
};

const verificarCdfProceso = async (id_proceso_compra, params = {}) => {
  return http_client(
    `/api/v1/cdf/proceso/${id_proceso_compra}/verificacion`,
    params
  );
};

const datosGeneralesAuditoria = async (id_proceso_compra, params = {}) => {
  return http_client(
    `/api/v1/compra/proceso/auditoria/${id_proceso_compra}/generales`,
    params,
    "get"
  );
};

const datosAdjudicacionAuditoria = async (id_proceso_compra, params = {}) => {
  return http_client(
    `/api/v1/compra/proceso/auditoria/${id_proceso_compra}/adjudicacion`,
    params,
    "get"
  );
};

// auditoria
const getEtapaByName = async (id_proceso_compra, params = {}) =>
  await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/etapa/nombre`,
    params
  );
const getAuditCambiosEtapa = async (id_proceso_compra, params = {}) =>
  await http_client(
    `/api/v1/compra/proceso/auditoria/${id_proceso_compra}/etapas`,
    params
  );
const getAuditImpugnacion = async (id_proceso_compra, params = {}) =>
  await http_client(
    `/api/v1/compra/proceso/auditoria/${id_proceso_compra}/subproceso-impugnaciones`,
    params
  );
const getSolicitudesNecesidad = async (id_proceso_compra, params = {}) =>
  await http_client(
    `/api/v1/compra/proceso/auditoria/${id_proceso_compra}/solicitudes-necesidad`,
    params
  );
const getBajaCuantia = async (id_proceso_compra, params = {}) =>
  await http_client(
    `/api/v1/compra/proceso/auditoria/${id_proceso_compra}/bajaCuantia`,
    params
  );
const getAuditContratos = async (id_proceso_compra, params = {}) =>
  await http_client(
    `/api/v1/compra/proceso/auditoria/${id_proceso_compra}/contratos/etapas`,
    params
  );

const getReporteGeneralBajaCuantiaADP = async (id_proceso_compra) =>
  await http_client(
    `/api/v1/compra/proceso/auditoria/${id_proceso_compra}/bajaCuantia/reporte/general`,
    {},
    "get",
    { responseType: "blob" },
    true,
    "blob",
    false
  );

const updateEtapasImpugnaciones = async (
  id_proceso_compra,
  id_etapa,
  params = {}
) =>
  await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/impugnaciones/etapas/${id_etapa}`,
    params,
    "put"
  );

const getSolicitudesCompraAuditoria = async (id_proceso_compra, params = {}) =>
  await http_client(
    `/api/v1/compra/proceso/auditoria/${id_proceso_compra}/solicitudes-compra`,
    params,
    "get"
  );

const generarReportePAC = async (create_now = "", params = {}) => {
  return await http_client(
    `/api/v1/reportes/pac/${create_now}`,
    params,
    "post"
  );
};

const pruebaCorreoMasivo = async (params = {}) => {
  return await http_client("/api/v1/indicadores/correo", params, "post");
};

const procesoCEEjecucion = async (id_proceso_compra) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/ejecucion`,
    {},
    "put"
  );
};

const procesosDisponibles = (params) =>
  http_client_with_status(
    `/api/v1/compra/proceso/listado/procesos/unidad/disponibles`,
    params,
    "get"
  );

// Carga docs anio fiscal
const cargarAniosFiscales = (params) =>
  http_client("/api/v1/anios", params, "get");

const addSubastaUnitaria = (id_sala, params = {}) =>
  http_client(`/api/v1/procesos/salas/unitaria/${id_sala}`, params, "post");

const imprimirPdfPip = (id_proceso_compra, params) =>
  http_client(
    `/api/v1/compra/proceso/imprimir/pip/pdf/${id_proceso_compra}`,
    params,
    "get",
    {},
    true,
    "blob"
  );

const eliminarAgrupador = async (id_sub_proceso, params) => {
  return await http_client(
    `/api/v1/compra/proceso/lotes/agrupador/${id_sub_proceso}`,
    params,
    "delete"
  );
};

const lotesParticipacionProveedores = (id_proceso_compra, params) =>
  http_client_with_status(
    `/api/v1/compra/proceso/lotes/revision/participacion-proveedor/${id_proceso_compra}`,
    params,
    "get"
  );

const lotesParticipacionProveedoresEvaluacion = (id_proceso_compra, params) =>
  http_client_with_status(
    `/api/v1/compra/proceso/lotes/revision/evaluacion/${id_proceso_compra}`,
    params,
    "get"
  );

const cargarDocumentoOferta = (idOfertaProveedor) =>
  http_client_with_status(
    `/api/v1/compra/proceso/lotes/documento-oferta/${idOfertaProveedor}`,
    null,
    "get",
    {},
    { responseType: "blob" }
  );

const agregarParticipacionProveedorLote = async (body = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/lotes/agregar/participacion-proveedor`,
    body,
    "put"
  );
};

const agregarOfertaProveedorLotes = async (
  id_participacion_proveedor,
  body = {}
) => {
  return await http_client(
    `/api/v1/compra/proceso/lotes/agregar/oferta-proveedor-participacion/${id_participacion_proveedor}`,
    body,
    "post",
    {
      "Content-Type": "multipart/form-data",
    }
  );
};

const agregarAdjuntoModificacionLotes = async (
  id_proceso_compra,
  body = {}
) => {
  return await http_client(
    `/api/v1/compra/proceso/lotes/agregar/adjunto-permitir-modificacion-lotes/${id_proceso_compra}`,
    body,
    "post",
    {
      "Content-Type": "multipart/form-data",
    }
  );
};

const obtenerAdjuntoModificacionLotes = (id_proceso_compra) =>
  http_client_with_status(
    `/api/v1/compra/proceso/lotes/documento-configuracion-lotes/${id_proceso_compra}`,
    null,
    "get",
    {},
    { responseType: "blob" }
  );

const agregarCalificacionOferta = async (id_oferta, params = {}) =>
  await http_client(
    `/api/v1/compra/proceso/lotes/agregar-calificacion-oferta/${id_oferta}`,
    params,
    "put"
  );

const eliminarOferta = async (id_oferta, params) => {
  return await http_client(
    `/api/v1/compra/proceso/lotes/oferta-proveedor/${id_oferta}`,
    params,
    "delete"
  );
};

export default {
  getPacProcesos,
  getSolicitudObs,
  saveProcesos,
  getSolicitudesAsignadasPAC,
  getSolicitudesParaAsignar,
  asignarSolicitudesPAC,
  eliminarSolicitudesPAC,
  getProceso,
  actualizarProceso,
  postProcesoCompra,
  deleteProcesoCompra,
  getProcesoEtapasActivas,
  actualizarProcesoEtapa,
  getProcesoModalidad,
  postNuevaModalidad,
  getModalidad,
  putModalidad,
  postNuevoHito,
  actualizarHito,
  postParticipanteModalidad,
  putParticipanteModalidad,
  getHito,
  guardarContenidoEtapa,
  guardarDocumentosProceso,
  deleteDocumentoProceso,
  descargarDocumentoProceso,
  obtenerDocumentosProceso,
  eliminarDocumentoProceso,
  getProveedoresParaProceso,
  saveGanadorProceso,
  getParticipantesGanadores,
  getGanadoresProcesos,
  actualizarEtapaProceso,
  cambiarOrdenHitos,
  eliminarPerfilesAutorizados,
  eliminarHito,
  publicarProceso,
  cambioEstadoProcesoCompra,
  getEtapaSolicitudes,
  emitirCdp,
  obtenerDocumentosProcesoProvider,
  inscripcionProceso,
  validarParticipacion,
  closeEtapaProceso,

  // interes presidencia
  getSupervisores,
  getSearchedSupervisores,
  postSupervisor,
  deleteSupervisor,
  markInteresPresidencia,
  unmarkInteresPresidencia,
  getListDocuments,
  estadoDocumentoProveedor,
  enviarDocumentoProveedor,
  getConsultasProceso,
  obtenerLotesConfigurados,
  storeLoteProceso,
  obtenerDatosLote,
  actualizarDatosLote,
  deleteLote,
  postProviderOffer,
  getProviderOffers,
  getProviderOffer,
  getProcessOffers,
  creacionSala,
  getSalas,
  addSubasta,
  removeSala,
  getSubastasSala,
  getSalasProceso,
  deleteProcessDocument,
  getGanadorSubasta,

  // proceso desierto
  putProcesoDesierto,
  postProcesoDesierto,

  //suspender proceso
  suspenderProceso,
  reanudarProceso,

  //estado etapa
  estadoEtapaProceso,
  editOfertaProveedor,
  getDocumentosProveedor,
  guardarDocumentosProcesoEtapa,
  putSalaSubasta,
  deleteSalaSubasta,
  getSubastaID,
  getSalaDatos,
  getTipoHitos,
  getDocumentoProcesoBuffer,

  //impugnaciones
  getEtapaActualProcesoImpugnacion,
  getEtapasProcesoImpugnacion,
  getDetailImpugnacion,
  getDetailEtapa,
  getDocumentoProceso,
  actualizarHitosProceso,
  getCantidadOfertasProceso,
  cancelarProceso,
  updateEtapasImpugnaciones,

  deleteOfertaProveedor,
  verificarCdfProceso,
  datosGeneralesAuditoria,
  datosAdjudicacionAuditoria,

  // auditoria
  getAuditCambiosEtapa,
  getAuditImpugnacion,
  getEtapaByName,
  getAuditContratos,
  getSolicitudesCompraAuditoria,
  getSolicitudesNecesidad,
  generarReportePAC,
  pruebaCorreoMasivo,
  getBajaCuantia,
  getTipoDocumentoProceso,
  getListTipoDocumento,
  getReporteGeneralBajaCuantiaADP,
  getConsultasProcesoAuditoria,
  procesoCEEjecucion,
  procesosDisponibles,

  // Carga documentos anios fiscales
  cargarAniosFiscales,
  imprimirPdfPip,
  addSubastaUnitaria,
  getEvaluacionPeo,
  getListadoSubastas,
  eliminarAgrupador,
  lotesParticipacionProveedores,
  cargarDocumentoOferta,
  agregarParticipacionProveedorLote,
  agregarOfertaProveedorLotes,
  agregarAdjuntoModificacionLotes,
  obtenerAdjuntoModificacionLotes,
  agregarCalificacionOferta,
  eliminarOferta,
  lotesParticipacionProveedoresEvaluacion,
  separarAgrupador,
  separarEspecifica,
};
