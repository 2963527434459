import { http_client, http_client_with_status } from "@/plugins/http_client";

const getTipoContrato = async (params = {}) => {
  return await http_client("/api/v1/contratacion/tipos", params, "get");
};

const postContrato = async (params = {}) => {
  return await http_client("/api/v1/contratacion", params, "post");
};

const getContratoEtapasOBS = async (id_contrato, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/${id_contrato}/etapas/obs`,
    params,
    "get"
  );
};

const getContratoEtapasEspecificaciones = async (id_obs, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/etapas/obs/${id_obs}/especificaciones`,
    params,
    "get"
  );
};

const getCantidadRestanteEtapa = async (id_especificacion, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/etapas/obs/especificaciones/${id_especificacion}`,
    params,
    "get"
  );
};

const postContratoEtapa = async (id_contrato, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/${id_contrato}/etapas`,
    params,
    "post"
  );
};

const getContratoEtapa = async (id_etapa, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/etapas/${id_etapa}`,
    params,
    "get"
  );
};

const getContratoEtapasList = async (id_contrato, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/${id_contrato}/etapas`,
    params,
    "get"
  );
};

const getContrato = async (id_contrato, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/${id_contrato}`,
    params,
    "get"
  );
};

const getEtapasProgreso = async (id_contrato, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/${id_contrato}/etapas/progreso`,
    params,
    "get"
  );
};

const getDocumentosContrato = async (id_contrato, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/${id_contrato}/documentos`,
    params,
    "get"
  );
};

const guardarDocumentosContrato = async (id_contrato, params) => {
  return await http_client(
    `/api/v1/contratacion/${id_contrato}/orden-inicio`,
    params,
    "post"
  );
};
const deleteDocumentoContrato = async (id_doc, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/documentos/${id_doc}`,
    params,
    "delete"
  );
};

const getDocumentoContrato = async (id_doc, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/documentos/${id_doc}`,
    params,
    "get",
    {},
    true,
    "arraybuffer"
  );
};

const getTiposDocumentos = async (params = {}) => {
  return await http_client(
    `/api/v1/contratacion/documentos/tipos`,
    params,
    "get"
  );
};

const deleteDetalleEtapa = async (id_etapa_detalle, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/etapas/detalles/${id_etapa_detalle}`,
    params,
    "delete"
  );
};

const deleteEtapa = async (id_etapa, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/etapas/${id_etapa}`,
    params,
    "delete"
  );
};

const postEtapaDetalle = async (id_etapa, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/etapas/${id_etapa}/detalles`,
    params,
    "post"
  );
};

const updateEtapa = async (id_etapa, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/etapas/${id_etapa}`,
    params,
    "put"
  );
};

const updateEtapaContrato = async (id_etapa, id_contrato, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/etapas/${id_etapa}/contrato/${id_contrato}`,
    params,
    "put"
  );
};

const updateEstadoContrato = async (id_contrato, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/${id_contrato}/estados`,
    params,
    "put"
  );
};

const getProgresoEtapasSeguimiento = async (id_contrato, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/${id_contrato}/etapas/completadas`,
    params,
    "get"
  );
};

const getProgresoEtapa = async (id_etapa, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/etapas/${id_etapa}/progreso`,
    params,
    "get"
  );
};

const postEntregaEtapa = async (id_etapa, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/etapas/${id_etapa}/entregas`,
    params,
    "post"
  );
};

const getTiposPagos = async (params = {}) => {
  return await http_client(`/api/v1/metodos/pago`, params, "get");
};

const getInstitucionesFinancieras = async (params = {}) => {
  return await http_client(`/api/v1/instituciones/financieras`, params, "get");
};

const getEntrega = async (id_etapa, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/etapas/${id_etapa}/entregas`,
    params,
    "get"
  );
};

const putEntrega = async (id_etapa, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/etapas/${id_etapa}/entregas`,
    params,
    "put"
  );
};

const getContratoGanador = async (id_ganador, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/ganadores/${id_ganador}`,
    params,
    "get"
  );
};

const putEntregaEstado = async (id_etapa, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/etapas/${id_etapa}/estados`,
    params,
    "put"
  );
};

const getContratoPagos = async (id_contrato, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/${id_contrato}/pagos`,
    params,
    "get"
  );
};

const postContratoPagos = async (id_etapa, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/etapas/${id_etapa}/pagos`,
    params,
    "post"
  );
};

const getContratoPago = async (id_pago, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/pagos/${id_pago}`,
    params,
    "get"
  );
};

const putContratoPago = async (id_pago, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/pagos/${id_pago}`,
    params,
    "put"
  );
};

const getMontosPagos = async (id_contrato, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/${id_contrato}/pagos/montos`,
    params,
    "get"
  );
};

const getContratoEspecificaciones = async (id_contrato, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/${id_contrato}/obs`,
    params,
    "get"
  );
};

const getGanadorProveedorInfo = async (id_ganador, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/ganadores/${id_ganador}/proveedores`,
    params,
    "get"
  );
};

const postContratoLiquidacion = async (id_contrato, params) => {
  return await http_client(
    `/api/v1/contratacion/${id_contrato}/liquidacion`,
    params,
    "post"
  );
};

const getContratoMultas = async (id_contrato, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/${id_contrato}/pagos/multas`,
    params,
    "get"
  );
};

const deleteContratoMulta = async (id_pago, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/pagos/${id_pago}`,
    params,
    "delete"
  );
};

const getContratoProcesosGanadores = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/procesos/${id_proceso_compra}/seguimientos/ganadores`,
    params,
    "get"
  );
};

const getConsolidadoMultas = async (id_contrato, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/${id_contrato}/pagos/multas/consolidados`,
    params,
    "get"
  );
};

const getTiposSanciones = async (params = {}) => {
  return await http_client(
    `/api/v1/proveedores/sanciones/descripciones`,
    params,
    "get"
  );
};

const getSancionesList = async (id_proveedor, params = {}) => {
  return await http_client(
    `/api/v1/proveedores/${id_proveedor}/sanciones`,
    params,
    "get"
  );
};

const postSanciones = async (id_proveedor, params = {}) => {
  return await http_client(
    `/api/v1/proveedores/${id_proveedor}/sanciones`,
    params,
    "post"
  );
};

const deleteSanciones = async (id_sancion, params = {}) => {
  return await http_client(
    `/api/v1/proveedores/sanciones/${id_sancion}`,
    params,
    "delete"
  );
};

const guardarActaLiquidacion = async (id_contrato, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/${id_contrato}/generacion/liquidacion`,
    params,
    "post",
    {'Content-Type': 'multipart/form-data'},
  );
};

const generarActaInconformidad = async (id_etapa, params = {}) => {
  return await http_client(
    `api/v1/contratacion/etapa/${id_etapa}/generacion/acta/inconformidad`,
    params,
    "post",
    {},
    true,
    "arraybuffer"
  );
};

const generarActaDeRecepcion = async (id_contrato_orden, params) => {
  return await http_client(
    `/api/v1/reportes/${id_contrato_orden}/actas/recepcion`,
    params,
    "post",
    {},
    true,
    "arraybuffer"
  );
};

const obtenerTiposDocumentosPorContrato = async (
  id_tipo_contrato,
  params = {}
) => {
  return await http_client(
    `/api/v1/contratacion/${id_tipo_contrato}/tipo-documentos`,
    params,
    "get"
  );
};

const obtenerInfoOrdenDeCompra = async (id_contrato_orden, params = {}) => {
  try {
    return await http_client(
      `/api/v1/contratacion/${id_contrato_orden}/copias`,
      params,
      "get"
    );
  } catch (error) {
    return error.response;
  }
};

const generarOrdenDeCompra = async (id_contrato_orden, params = {}) => {
  try {
    return await http_client(
      `/api/v1/reportes/${id_contrato_orden}/actas/orden-compra`,
      params,
      "post",
      {},
      true,
      "arraybuffer"
    );
  } catch (error) {
    return error.response;
  }
};

const postDocumentosContrato = async (id_contrato, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/${id_contrato}/documentos`,
    params,
    "post"
  );
};

// modificacion de plazo contractual
const postNewPlazo = async (body) =>
  await http_client("/api/v1/plazo-contractual-cambios", body, "post");

// envio de documento de garantia
const postGarantia = async (id_contrato_orden, body) =>
  await http_client(
    `/api/v1/contratacion/${id_contrato_orden}/garantia/`,
    body,
    "post"
  );

const getActaRecepcion = async (id_acta, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/etapas/acta/${id_acta}`,
    params,
    "get",
    {},
    true,
    "arraybuffer"
  );
};

const getProcesosAdminContratos = async (params = {}) => {
  return await http_client(
    `/api/v1/contratacion/administrador/contratos`,
    params,
    "get"
  );
};

const verificacionCdfContrato = async (idProcesoCompra) => {
  return await http_client(`/api/v1/cdf/contrato/${idProcesoCompra}`, {});
};

// registro de etapas
const getLotesEtapa = async (id_contrato) => await http_client(`/api/v1/contratacion/${id_contrato}/etapas/lotes`, {});

const getMovimientoContrato = async (id_etapa, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/etapas/${id_etapa}/tipo-movimiento-contrato`,
    params,
    "get"
  );
};

const visualizacionPdf = (idContrato) => http_client_with_status(`/api/v1/contratacion/visualizar-pdf/${idContrato}`, null, 'get', {}, { responseType: 'blob' });

const generarActaLiquidacion = async (id_contrato, params = {}) => {
  return await http_client(
    `/api/v1/contratacion/${id_contrato}/generar-acta/liquidacion`,
    params,
    "post",
    {},
    true,
    "arraybuffer"
  );
};


export default {
  getTipoContrato,
  postContrato,
  getContratoEtapasOBS,
  getContratoEtapasEspecificaciones,
  getCantidadRestanteEtapa,
  postContratoEtapa,
  getContratoEtapa,
  getContratoEtapasList,
  getContrato,
  getEtapasProgreso,
  getDocumentosContrato,
  deleteDetalleEtapa,
  deleteEtapa,
  postEtapaDetalle,
  updateEtapa,
  updateEstadoContrato,
  getProgresoEtapasSeguimiento,
  getProgresoEtapa,
  postEntregaEtapa,
  getTiposPagos,
  getInstitucionesFinancieras,
  getEntrega,
  putEntrega,
  getContratoGanador,
  putEntregaEstado,
  guardarDocumentosContrato,
  getTiposDocumentos,
  deleteDocumentoContrato,
  getDocumentoContrato,
  getContratoPagos,
  postContratoPagos,
  getContratoPago,
  putContratoPago,
  getMontosPagos,
  getContratoEspecificaciones,
  getGanadorProveedorInfo,
  postContratoLiquidacion,
  getContratoMultas,
  deleteContratoMulta,
  getContratoProcesosGanadores,
  getConsolidadoMultas,
  getTiposSanciones,
  getSancionesList,
  postSanciones,
  deleteSanciones,
  guardarActaLiquidacion,
  generarActaInconformidad,
  generarActaDeRecepcion,
  obtenerTiposDocumentosPorContrato,
  obtenerInfoOrdenDeCompra,
  generarOrdenDeCompra,
  postDocumentosContrato,
  postNewPlazo,
  getActaRecepcion,
  postGarantia,
  getProcesosAdminContratos,
  verificacionCdfContrato,
  getLotesEtapa,
  updateEtapaContrato,
  getMovimientoContrato,
  visualizacionPdf,
  generarActaLiquidacion,
};