const routerAdminContratos = [
  {
    path: "/contratos-asignados",
    name: "contratos-asignados",
    component: () =>
      import(
        /* webpackChunkName: "procesosAsignados" */ "./ProcesosAsignadosView.vue"
      ),
  },
];

export default routerAdminContratos;
