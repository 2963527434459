<template>
  <v-card width="600" style="overflow: hidden">
    <section>
      <div class="d-flex align-center justify-space-between mb-4">
        <p class="mb-0 mx-4">Notificaciones</p>
        <v-btn text small class="custom-read-btn" @click="markAllAsRead">
          Marcar como leídas
          <v-icon class="ml-2" size="18"
            >mdi-checkbox-marked-circle-outline</v-icon
          >
        </v-btn>
      </div>
      <v-row
        @scroll="onScroll"
        class="notificationsContainer"
        v-if="notificaciones_list?.length > 0"
        id="lastItem"
      >
        <v-col cols="12">
          <v-list two-line>
            <v-list-item-group>
              <template v-for="notificacion in notificaciones_list">
                <v-list-item
                  :class="!notificacion.read ? 'blue-grey lighten-5' : ''"
                >
                  <v-list-item-content @click="readNotification(notificacion)">
                    <v-list-item-title class="my-4 text-wrap">{{
                      notificacion.detalle?.contenido
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      ><span>{{
                        moment(
                          notificacion.detalle?.created_at
                        ).calendar() || ""
                      }}</span></v-list-item-subtitle
                    >
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text
                      ><v-icon @click="deleteNotification(notificacion)"
                        >mdi-close-circle-outline</v-icon
                      ></v-list-item-action-text
                    >
                  </v-list-item-action>
                </v-list-item>
                <v-divider />
              </template>
              <div v-if="loadingMore" class="d-flex justify-center py-10">
                <v-progress-circular
                  indeterminate
                  color="secondary"
                ></v-progress-circular>
              </div>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </section>
  </v-card>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import moment from "moment";
export default {
  name: "NotificacionesComponent",
  data: () => ({
    currentPage: 1,
    loadingMore: false,
  }),
  computed: {
    ...mapState(["selectedUnidad", "notificaciones_list"]),
  },
  methods: {
    ...mapMutations(["setNotificacionesPendientes", "setNotificaciones"]),

    async onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      const element = document.getElementById("lastItem");
      let maxHeight = scrollHeight - clientHeight;
      if (scrollTop == maxHeight) {
        this.currentPage += 1;
        this.loadingMore = true;

        let params = {
          id_perfil: this.selectedUnidad?.cargo[0]?.id,
          id_institucion: this.selectedUnidad?.instituciones?.id,
          page: this.currentPage,
        };
        const {
          status,
          data: notificaciones,
        } = await this.services.Notificaciones.getNotificaciones(params);

        if (notificaciones?.length == 0) {
          this.temporalAlert({
            show: true,
            message: "No se han encontrado notificaciones nuevas",
            type: "info",
          });
        }

        this.loadingMore = false;
        // this.setNotificacionesPendientes(notificaciones_pendientes);
        this.setNotificaciones([...notificaciones, ...this.notificaciones_list]);
        element.scrollTop = 0;
      }
    },
    async readNotification(notificacion) {
      const { status } = await this.services.Notificaciones.markAsRead(
        notificacion.id
      );

      const {
        detalle: { action },
      } = notificacion;

      if (status == 200 && action?.redirect_to) {
        this.$router.replace({
          name: action?.redirect_to?.name,
          params: action?.redirect_to?.params,
        });
      }
    },
    async markAllAsRead() {
      const { status } =
        await this.services.Notificaciones.readAllNotifications();

      if (status == 200) {
        const { data } = await this.services.Notificaciones.getNotificaciones();
        this.setNotificaciones(data.reverse());
      }
    },
    async deleteNotification(notificacion) {
      const { status } = await this.services.Notificaciones.deleteNotification(
          notificacion?.id
      );
      if (status == 200) {
        const { data } = await this.services.Notificaciones.getNotificaciones();
        this.setNotificaciones(data.reverse());
      }
    },
  },
};
</script>
<style scoped>
.custom-read-btn {
  text-transform: initial;
  font-size: 16px;
  letter-spacing: initial !important;
}

.notificationsContainer {
  overflow-y: scroll;
  max-height: 410px;
}

.notificationsContainer::-webkit-scrollbar {
  width: 7px !important;
  background-color: #ffffff;
}
.notificationsContainer::-webkit-scrollbar-thumb {
  background: #757575;
  border-radius: 20px;
}
.theme--light.v-list-item--active:before {
  opacity: 0 !important;
}
</style>
