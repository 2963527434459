import Vue from "vue";
import VueRouter from "vue-router";
import VueApexCharts from "vue-apexcharts";
import jwtDecode from "jwt-decode";
import moment from "moment-timezone";

import administracion from "../views/AdministracionObs/router";
import aprobacionUFI_convenioMarco from "../views/AprobacionUFI/router";
import aprovacionProveedorRouter from "../views/AprobacionProveedor/router";
import convenioMarco from "../views/ConvenioMarco/router";
import conveniosMarcoProveedorRoutes from "../views/ConveniosProveedor/router";
import jefeAreaAprobacion from "../views/JefeAreaAprobacion/router";
import ordenCompra from "../views/ConvenioMarcoUACI/router";
import perfilRouter from "../views/PerfilProveedor/router";
import routerGestionUsuarios from "../views/GestionUsuarios/router";
import routerTechosPresupuestarios from "../views/TechosPresupuestarios/router";
import solicitudOBS from "../views/Solicitud/router";
import routerCompras from "../views/ProcesoCompra/router";
import routerPaacProveedor from "../views/PaacProveedor/router";
import routerPaacUaci from "../views/PaacUaci/router";
import routerReporteria from "../views/Reporteria/router";
import routerConfiguracion from "../views/Configuraciones/router";
import routerSancionProveedor from "../views/SancionesProveedor/router";
import routerCifradosPresupuestarios from "../views/CifradosPresupuestarios/router";
import routerRupes from "../views/Rupes/router";
import solicitudCompras from "../views/Dinac/router";
import administracionSanciones from "../views/AdministracionSanciones/router";
import routerConfiguracionDocumento from "../views/Configuracion/router";
import routesContralores from "../views/Contralores/router";
import routesPac from "../views/Pac/router";
import routesProcesoTradicional from "../views/SolicitudCompraModule/ProcesoTradicional/router";
import routesProcesoCompraDoc from "../views/ProcesoCompraDoc/router";
import routesProcesoCompraDocv1 from "../views/ProcesoCompraDocv1/router";
import routesModalidades from "../views/configuracionProcesoCompra/router";
import adminInstituciones from "../views/AdminInstituciones/router";
import routesBajaCuantia from "../views/bajaCuantia/router";
import routerTarjetas from "../views/Tarjetas/router";
import routerSupervisor from "../views/Supervisor/router";
import routeReporteProveedor from "../views/ReporteProveedores/router";
import routesProcCompReserva from "../views/ProcesoCompraReserva/router";
import routerPrevenciones from "../views/PrevencionProcesos/router";

import routerPeo from "../views/peo/router";
import routesAsignacionCDP from "../views/AsignacionCDP/router";
import routerAdminContratos from "../views/AdministradoresContrato/router";
import routeCompraLinea from "../views/solicitudCompraLinea/router";
import routeCdf from "../views/Cdf/router";
import adminCatalogoCifrados from "../views/AdminCatalogoCifrados/router";
import routerIndicadores from "../views/Estadisticas/router";
import routerConvocatorias from "@/views/CmConvocatorias/router";
import routesCompraEnLinea from "@/views/compraEnLinea/router";
import routesCompraPorEmergencia from "@/views/CompraPorEmergencia/router";
import aprobacionPacAnioFiscalRoutes from "@/views/aprobacionPacAnioFiscal/router";
import seguimientoProcesosRoutes from "@/views/seguimientoProcesos/router";
import busquedaInformacionRpnRoutes from "@/views/busquedaInformacionRPN/router";
import cmReporteriaRoutes from "@/views/CmReporteria/router";

import routerComprasConjuntas from "@/views/ComprasConjuntas/router";

import panelAdministracionOdcRoutes from "@/views/PanelAdministracionODC/router";

import routesCompraExterior from "@/views/CompraExterior/router";

/**
 * Módulo de catálogo electrónico
 */
import catalogoEnLineaRoutes from "../views/CatalogoEnLinea/router";
import cmEstructura from "../views/CMEstructura/router";
import cmOrdenCompra from "../views/CMOrdenCompra/router";
import cmConvenio from "../views/CMConvenio/router";
import cmCumplimientoSolicitudesRoutes from "../views/CMCumplimientoSolicitudes/router";
import cmProveedoresDashboardRoutes from "../views/CMProveedoresDashboard/router";
import admCMRegion from "../views/AdminCMRegion/router";
import admCMAtributos from "../views/AdminCMAtributo/router";
import routerCmSolicitud from "../views/CMProcesoAprobacionCompraElectronica/router";
import cmSolicitudesConObservacionRoutes from "../views/CMSolicitudesConObservacion/router";
import cmContactosProveedorRoutes from "../views/CmContactosProveedor/router";

import reporteriaDirector from "../views/reporteriaDirector/router";

import routesDelegadosUCP from "../views/DelegadosUcp/router";

import routesReasignacionContractual from "../views/ReasignacionesContratos/router/index";

import centroEscolarRoutes from '@/views/comprasCentrosEscolares/router';

import evaluarPermisosUrl from '@/utils/evaluarPermisosUrl.mjs';

import rutasInteligenciaMercado from '@/views/inteligenciaMercado/router';

Vue.use(VueRouter);
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

const routes = [
  {
    path: "/",
    component: () => import(/* webpackChunkName: "layout" */ "../views/layout"),
    children: [
      {
        path: "/",
        name: "dashboard",
        component: () =>
          import(/* webpackChunkName: "profiles" */ "../views/dashboardView"),
      },

      {
        path: "profile",
        name: "profile",
        component: () =>
          import(/* webpackChunkName: "profiles" */ "../views/profileView"),
      },
      {
        path: "/security",
        name: "security",
        component: () =>
          import(/* webpackChunkName: "qr" */ "../views/Auth/securityView"),
        meta: { requiresAuth: false },
      },
      {
        path: "/verificacion-documentos",
        name: "verificacion-documentos",
        component: () =>
          import(
            /* webpackChunkName: "verificacionDocumentosView" */ "../views/PerfilProveedor/verificacionDocumentosView.vue"
          ),
      },
      {
        path: "/administracion-asuetos",
        name: "administracion-asuetos",
        component: () =>
          import(
            /* webpackChunkName: "adminAsuetoView" */ "../views/adminAsuetoView.vue"
          ),
      },
      ...perfilRouter,
      ...aprovacionProveedorRouter,
      ...conveniosMarcoProveedorRoutes,
      ...administracion,
      ...convenioMarco,
      ...ordenCompra,
      ...jefeAreaAprobacion,
      ...solicitudOBS,
      ...aprobacionUFI_convenioMarco,
      ...routerTechosPresupuestarios,
      ...routerGestionUsuarios,
      ...routerCompras,
      ...routerPaacProveedor,
      ...routerPaacUaci,
      ...routerReporteria,
      ...routerConfiguracion,
      ...routerSancionProveedor,
      ...routerCifradosPresupuestarios,
      ...routerRupes,
      ...solicitudCompras,
      ...administracionSanciones,
      ...routerConfiguracionDocumento,
      ...routesContralores,
      ...routesPac,
      ...routesProcesoTradicional,
      ...routerPeo,
      ...routesProcesoCompraDoc,
      ...routesProcesoCompraDocv1,
      ...routesModalidades,
      ...adminInstituciones,
      ...routesBajaCuantia,
      ...routesAsignacionCDP,
      ...routerTarjetas,
      ...routerSupervisor,
      ...routeReporteProveedor,
      ...routesProcCompReserva,
      ...routerPrevenciones,
      ...routerAdminContratos,
      ...routeCompraLinea,
      ...routeCdf,
      ...adminCatalogoCifrados,
      ...routerIndicadores,
      ...catalogoEnLineaRoutes,
      ...cmEstructura,
      ...admCMRegion,
      ...admCMAtributos,
      ...cmConvenio,
      ...cmCumplimientoSolicitudesRoutes,
      ...cmProveedoresDashboardRoutes,
      ...routerCmSolicitud,
      ...cmSolicitudesConObservacionRoutes,
      ...routerConvocatorias,
      ...routesCompraEnLinea,
      ...routesCompraPorEmergencia,
      ...aprobacionPacAnioFiscalRoutes,
      ...seguimientoProcesosRoutes,
      ...busquedaInformacionRpnRoutes,
      ...cmReporteriaRoutes,
      // ...routerComprasConjuntas,
      ...reporteriaDirector,
            ...cmOrdenCompra,
      ...panelAdministracionOdcRoutes,
      ...routesCompraExterior,
      ...cmContactosProveedorRoutes,
      ...routesDelegadosUCP,
      ...routesReasignacionContractual,
      ...centroEscolarRoutes,
      ...rutasInteligenciaMercado,
    ],
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../views/Auth/registerView.vue"
      ),
    meta: { requiresAuth: false },
  },
  {
    path: "/identidad-digital",
    name: "identidad-digital",
    component: () =>
      import(/* webpackChunkName: "sso" */ "../views/Auth/ssoView"),
    meta: { requiresAuth: false },
    redirect: { name: "login" },
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Auth/loginView"),
    meta: { requiresAuth: false },
  },
  {
    path: "/recuperar-password",
    name: "recuperar-password",
    component: () =>
      import(
        /* webpackChunkName: "recuperarPassword" */ "../views/Auth/recoverPasswordView"
      ),
    meta: { requiresAuth: false },
  },
  {
    path: "/reset-password/:id",
    name: "reset-password",
    component: () =>
      import(
        /* webpackChunkName: "resetPassword" */ "../views/Auth/resetPasswordView"
      ),
    meta: { requiresAuth: false },
  },
  {
    path: "/reset-password-and-report/:token",
    name: "reset-password-and-report",
    component: () =>
      import(
        /* webpackChunkName: "resetPasswordAndReport" */ "../views/Auth/resetPasswordAndReportView"
      ),
    meta: { requiresAuth: false },
  },
  {
    path: "/verify-mail/:token",
    name: "verifyMail",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Auth/verifyMailView"),
    meta: { requiresAuth: false },
  },
  {
    path: "/autenticacionqr",
    name: "2fa",
    component: () =>
      import(/* webpackChunkName: "qr" */ "../views/Auth/2faView"),
    meta: { requiresAuth: false },
  },
  {
    path: "/observaciones",
    name: "revisar-observaciones",
    component: () =>
      import(
        /* webpackChunkName: "observaciones" */ "../views/Auth/registerView.vue"
      ),
    meta: { requiresAuth: false },
  },
  {
    path: "/registro-funcionario",
    name: "registro-funcionario",
    component: () =>
      import(
        /* webpackChunkName: "registroFuncionarioView" */ "../views/Auth/registroFuncionarioView.vue"
      ),
    meta: { requiresAuth: false },
  },
  {
    path: "/calculadora-razonabilidad-precios",
    name: "calculadora-razonabilidad-precios",
    component: () =>
      import(
        /* webpackChunkName: "calculadoraRazonabilidadPrecios" */ "../views/CalculadoraRazonabilidadPrecios/calculadoraView.vue"
      ),
    meta: { requiresAuth: false },
  },
  {
    path: "/*",
    name: "Forbidden",
    component: () =>
      import(/* webpackChunkName: "forbidden" */ "../views/forbiddenView"),
    meta: { requiresAuth: false },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  let token = null;
  let perfiles = [];
  let esOficialCumplimientoSuspendido = false;

  evaluarPermisosUrl(to, from, next);

  if (to.matched.some((record) => record.meta.requiresAuth !== false)) {
    token = localStorage.getItem("token");
    if (!token) {
      next("/login");
      return;
    } else {
      const userDecode = jwtDecode(token);
      let expiracion = moment(new Date(userDecode.exp * 1000));
      let actual = moment(new Date(Date.now()));
      if (
        actual.diff(expiracion, "days") > 0 ||
        actual.diff(expiracion, "hours") > 0 ||
        actual.diff(expiracion, "minutes") > 0
      ) {
        localStorage.clear();
        next("/login");
      } else {
        const userDecode = jwtDecode(token);
        perfiles = userDecode.perfiles ?? [];

        if (
          !token ||
          userDecode.roles.find((roles) => roles === "ROLE_PROVEEDOR")
        ) {
          const docsProveedorValidados =
            await Vue.prototype.DocsProveedorValidados(to);
          if (docsProveedorValidados && to.name !== "verificacion-documentos")
            next({ name: "verificacion-documentos" });

          const sancionActivaProveedor =
            await Vue.prototype.validarSancionesActivasProveedor(to);
          if (sancionActivaProveedor) next({ name: "proveedor-sancionado" });
        }

        esOficialCumplimientoSuspendido = Boolean(
          userDecode?.user?.es_oficial_de_cumplimiento_suspendido
        );
      }
    }
  }

  const canNext = await Vue.prototype.canNext(to);

  if (canNext === true) {
    next();
  } else {
    const tieneAccesoPanelOdc = Vue.prototype.verificarRuta(
      "panel-administracion-odc"
    );

    if (
      perfiles.includes("OFICIAL DE CUMPLIMIENTO") &&
      tieneAccesoPanelOdc &&
      esOficialCumplimientoSuspendido
    ) {
      Vue.prototype.pushAppMessage({
        type: "info",
        message: "Su usuario se encuentra suspendido.",
      });
      next({ name: "panel-administracion-odc" });
      return;
    }

    next({ name: "Forbidden" });
  }
});

export default router;
