<template>
    <Fragment>
        <div>
            <div class="active-cart-request-header mb-4">
                <TickerComponent 
                    :isExpired="isRequestExpired" 
                    :tick="transformDuration(timer.tick)" 
                />
                <template v-if="!isRequestExpired">
                    <h3>OFERTAS EN SOLICITUD</h3>
                    <p class="mb-0">{{ request?.CMRegion?.nombre }}</p>
                </template>
            </div>
            <CartItemsTableComponent :request="request" :isEditAction="isEditAction" :offers="offersInCart"
                :isRequestExpired="isRequestExpired"
                :baseUrl="isEditAction ? `/catalogo-electronico/producto/editar/${request.id}` : '/catalogo-electronico/producto'" />
            <EmptyComponent 
                v-if="!isRequestExpired" 
                :empty="!(offersCount > 0)" 
                colored
                text="Solicitud vacia, agrega elementos al carrito."
            >
                <template v-slot:extra>
                    <v-btn class="mt-4" color="primary" @click.stop="handleCtaClick">Agregar</v-btn>
                    <ConfirmationModalComponent 
                        :isOpen="confirmationOpen" 
                        description="¿Desea continuar con la acción?"
                        @confirm="handleConfirmation" 
                        @cancel="confirmationOpen = false"
                        :isLoading="confirmAction.isLoading" 
                    />
                </template>
            </EmptyComponent>
        </div>
        <div class="mt-4 d-flex flex-column flex-md-row" style="gap: 16px;">
            <div class="flex-shrink-1 flex-grow-1 order-2 order-md-1">
                <RequestCancellerComponent 
                    :isEmptyRequest="isEmptyRequest" 
                    :request="request" 
                />
            </div>
            <div v-if="!isEmptyRequest && !isRequestExpired && offersCount > 0"
                class="flex-shrink-1 flex-grow-1 order-1 order-md-2">
                <PurchaseConfirmationComponent
                    :request="request" 
                />
            </div>
        </div>
    </Fragment>
</template>
<script>
import PurchaseConfirmationComponent from './PurchaseConfirmationComponent.vue';
import RequestCancellerComponent from './RequestCancellerComponent.vue';
import { transformDuration } from '@/utils/countdown';
import CartItemsTableComponent from './CartItemsTableComponent.vue';
import { EmptyComponent } from '@/components/utils';
import { createLoadable, toggleLoadable, setLoadableResponse, isResponseSuccesful } from '@/utils/loadable';
import { mapActions, mapMutations } from "vuex";
import TickerComponent from './TickerComponent.vue';
import ConfirmationModalComponent from '@/components/utils/ConfirmationModalComponent.vue';
import { Fragment } from 'vue-fragment';

export default {
    name: 'ActiveCartRequest',
    components: {
        PurchaseConfirmationComponent,
        RequestCancellerComponent,
        CartItemsTableComponent,
        TickerComponent,
        EmptyComponent,
        ConfirmationModalComponent,
        Fragment,
    },
    props: {
        timer: { type: Object },
        request: { type: Object, required: true },
        offersInCart: { type: Array },
        isEditAction: { type: Boolean, default: false, }
    },
    data: () => ({
        confirmationOpen: false,
        confirmAction: createLoadable(null),
    }),
    computed: {
        offersCount() {
            return this.offersInCart.length;
        },
        isRequestExpired() {
            return this.timer.status === 'expired';
        },
        isEmptyRequest() {
            return this.timer.status === 'empty-request';
        },
    },
    methods: {
        transformDuration,
        ...mapActions("catalogoEnLinea", ["getOpenRequest"]),
        async handleConfirmation() {
            this.confirmationOpen = false;
            toggleLoadable(this.confirmAction);
            const data = await this.services.CMSolicitudesConObservacion.markRequestAsActive(this.request.id);

            setLoadableResponse(this.confirmAction, data);
            if (!isResponseSuccesful(data)) return;;

            this.$router.push('/catalogo-electronico')
            this.getOpenRequest();
        },
        handleCtaClick() {
            if (this.isEditAction) {
                this.confirmationOpen = true;
                return;
            }

            this.$router.push('/catalogo-electronico')
        },
    },
}
</script>
<style>
.active-cart-request-header .ticker {
    float: right;
    font-weight: 600;
}

.cart-overlay-total {
    display: block;
    font-size: 36px;
    font-weight: 600;
    text-align: center;
}
</style>