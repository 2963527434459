const perfilRouter = [
  {
    path: "/perfil_proveedor/:idProveedor?",
    name: "perfil_proveedor",
    component: () =>
      import(
        /* webpackChunkName: "perfil proveedor" */ "./perfilProveedorView"
      ),
  },
  {
    path: "/agregar-lista/:type",
    name: "agregar-lista",
    component: () =>
      import(
        /* webpackChunkName: "agregar lista" */ "./components/AgregarListaComponent.vue"
      ),
  },
  {
    path: "/proveedor/:id_proveedor/sanciones",
    name: "sanciones-proveedor",
    component: () => 
      import(
        /* webpackChunkName: "contratos-proveedor" */ "./SancionesProveedorView.vue" 
      )
  },
  {
    path: "/configuracion-obs/:id",
    name: "configuracion-obs",
    component: () =>
      import(
        /* webpackChunkName: "configuacion de OBS" */ "./configuracionOBSView.vue"
      ),
  },
  {
    path: "/documentos",
    name: "documentos",
    component: () =>
      import(
        /* webpackChunkName: "agregar documentacion" */ "./agregarDocumentacionView.vue"
      ),
  },
  {
    path: "/accionistas/:idProveedor?",
    name: "accionistas",
    component: () =>
      import(
        /* webpackChunkName: "agregar accionista" */ "./agregarAccionistaView2.vue"
      ),
  },
  {
    path: "/accionistas/asociados/:id_accionista/:idProveedor?",
    name: "accionistas-asociados",
    component: () =>
      import(
        /* webpackChunkName: "agregar accionista asociado" */ "./agregarAccionistasAsociadosView.vue"
      ),
  },
  {
    path: "/contactos",
    name: "contactos",
    component: () =>
      import(
        /* webpackChunkName: "agregar contacto" */ "./agregarContactoView.vue"
      ),
  },
  {
    path: "/pac-instituciones",
    name: "pac-instituciones",
    component: () =>
      import(
        /* webpackChunkName: "Pac Instituciones" */ "./pacInstituciones.vue"
      ),
  },
];

export default perfilRouter;
