<template>
    <Fragment>
        <v-alert v-if="requestLoadable.isLoading" dense type="info">
            Cargando carrito...
        </v-alert>
        <template v-else>
            <RetryDataLoading :loadable="requestLoadable" :retry-handler="getOpenRequest">
                <template v-slot:icon>
                    <v-icon size="40" color="primary">mdi-alert</v-icon>
                </template>
                <v-alert dense outlined type="error" color="var(--v-error-lighten2)" v-if="requestsToModifyCount > 0">
                    <strong>Hay algunas solicitudes que necesitan modificación, para verlas haz click
                        <b @click.stop="$router.push({path:'/cm-aprobacion-solicitud-compra',query:{active:'solicitudesConObservacion'}})"
                            style="cursor: pointer; text-decoration: underline;">
                            aqui.
                        </b>
                    </strong>
                </v-alert>
                <ActiveCartRequest v-if="hasActiveRequest" 
                    :request="request" 
                    :timer="timer" 
                    :offers-in-cart="cartOffers"
                    :isEditAction="isEditAction"
                />
                <CreateRequestComponent v-else />
            </RetryDataLoading>
        </template>
    </Fragment>
</template>

<script>
import CreateRequestComponent from '@/components/catalogoEnLinea/cart/CreateRequestComponent.vue';
import ActiveCartRequest from "@/components/catalogoEnLinea/cart/ActiveCartRequest.vue";
import { Fragment } from 'vue-fragment';
import { RetryDataLoading } from '@/components/utils';
import { mapActions } from 'vuex';

export default {
    name: 'CartDrawerComponent',
    components: {
        CreateRequestComponent,
        ActiveCartRequest,
        Fragment,
        RetryDataLoading
    },
    props: {
        timer: {
            type: Object,
            required: false,
        },
        hasActiveRequest: {
            type: Boolean,
            required: true,
        },
        requestLoadable: {
            type: Object,
            required: true,
        },
        requestsToModifyCount: {
            type: Number,
            required: false,
        },
        isEditAction: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        request() {
            return this.requestLoadable.data?.solicitud;
        },
        cartOffers() {
            return this.requestLoadable.data?.productosActualizados ?? [];
        },
    },
    methods: {
        ...mapActions("catalogoEnLinea", ["getOpenRequest"]),
    },
}
</script>