import { isNil } from 'lodash';

const FormMessages = {
    'required': 'Campo requerido',
    'maxLength': 'Máxima longitud alcanzada ({VALUE})',
    'minValue': 'Valor minimo requerido ({VALUE})',
    'maxValue': 'Valor máximo alcanzado ({VALUE})',
    'txtField': 'Campo solo admite caracteres alfanuméricos',
    'decimal': 'El valor ingresado debe ser un número decimal',
    'integer': 'El valor ingresado debe ser un número entero',
    'numeric': 'El valor ingresado debe ser un valor numérico',
    'tel': 'El valor no es un teléfono válido',
    'regex': 'El campo no es un valor válido'
};

const allowedValidations = ['required', 'decimal', 'integer', 'maxLength', 'minValue', 'maxValue', 'txtField', 'numeric', 'tel', 'regex'];

export class Validator {
    field = null

    fieldDirty = false;

    errors = []

    constructor(fieldConfig) {
        this.field = fieldConfig;
        this.fieldDirty = fieldConfig.$dirty;
    }

    detect() {
        for (let i = 0; i < allowedValidations.length; i++) {
            const validation = allowedValidations[i];
            !isNil(this.field[validation]) && this[validation]();
        }

        return this;
    }

    required(message = FormMessages.required) {
        if (this.fieldDirty && !this.field.required) {
            this.errors.push(message);
        }

        return this;
    }

    maxLength(message = FormMessages.maxLength) {
        if (this.fieldDirty && !this.field.maxLength) {
            const { max } = this.field.$params.maxLength;
            this.errors.push(message.replace('{VALUE}', max));
        }

        return this;
    }

    minValue(message = FormMessages.minValue) {
        if (this.fieldDirty && !this.field.minValue) {
            const { min } = this.field.$params.minValue;
            this.errors.push(message.replace('{VALUE}', min));
        }

        return this;
    }

    maxValue(message = FormMessages.maxValue) {
        if (this.fieldDirty && !this.field.maxValue) {
            const { max } = this.field.$params.maxValue;
            this.errors.push(message.replace('{VALUE}', max));
        }

        return this;
    }

    txtField(message = FormMessages.txtField) {
        if (this.fieldDirty && !this.field.txtField) {
            this.errors.push(message);
        }

        return this;
    }

    decimal(message = FormMessages.decimal) {
        if (this.fieldDirty && !this.field.decimal) {
            this.errors.push(message);
        }

        return this;
    }

    integer(message = FormMessages.integer) {
        if (this.fieldDirty && !this.field.integer) {
            this.errors.push(message);
        }

        return this;
    }

    numeric(message = FormMessages.numeric) {
        if (this.fieldDirty && !this.field.numeric) {
            this.errors.push(message);
        }

        return this;
    }

    tel(message = FormMessages.tel) {
        if (this.fieldDirty && !this.field.tel) {
            this.errors.push(message);
        }

        return this;
    }

    regex(message = FormMessages.regex) {
        if (this.fieldDirty && !this.field.regex) {
            this.errors.push(message);
        }

        return this;
    }

    getResult() {
        return this.errors;
    }
}
